// Dashboard.js
import React, { useState } from "react";
import { useWallet } from "../context/WalletContext";
import ProfileModal from "./ProfileModal";
import copyIcon from "../copy-svgrepo-com.svg";
import SearchResults from "./Search/SearchResults";
import arrowDownIcon from "../assets/icons/arrow-down-3101.svg";
import CachedImage from "./CachedImage";
import walletIcon from "../assets/icons/Icon_wallet.png";
import paramIcon from "../assets/icons/Icon_param.png";

const Dashboard = ({
  name,
  eth,
  bio,
  saveProfile,
  game,
  tournamentId,
  currentUserId,
}) => {
  const { account } = useWallet();
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [notification, setNotification] = useState("");
  const [isProfileExpanded, setIsProfileExpanded] = useState(false);

  const formatAddress = (address) => {
    if (!address) return "";
    const start = address.slice(0, 2);
    const end = address.slice(-5);
    return `${start}...${end}`;
  };

  const handleCopy = () => {
    const textToCopy = account;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setNotification("Copié dans le presse papier !");
        setTimeout(() => {
          setNotification("");
        }, 2000);
      })
      .catch((err) => {
        setNotification("Fail..." + err);
        setTimeout(() => {
          setNotification("");
        }, 2000);
      });
  };

  return (
    <div>
      <SearchResults currentUserId={currentUserId} />
      <div className={`profil ${isProfileExpanded ? "expanded" : ""}`}>
        <div className="profil_info">
          <div className="profil_title">
            <p
              style={{
                fontWeight: "bold",
                fontSize: "19px",
                cursor: "pointer",
              }}
              onClick={() => setIsProfileModalOpen(true)}
            >
              {name}
            </p>

            <div className="copied-text">
              <span>{formatAddress(account)}</span>
              <img
                src={copyIcon}
                onClick={handleCopy}
                alt="Copy icon"
                className="copy-icon"
              />
            </div>
          </div>
          <img
            src={arrowDownIcon}
            onClick={() => setIsProfileExpanded(!isProfileExpanded)}
            alt="Expand icon"
            className={`expand-icon ${isProfileExpanded ? "expanded" : ""}`}
          />
        </div>
        {isProfileExpanded && (
          <div
            className={`profil_info_details ${
              isProfileExpanded ? "expanded" : ""
            }`}
          >
            <div className="profilsIcons">
              <CachedImage src={walletIcon} alt="Wallet" className="icon" />
            </div>
            <div className="profilsIcons">
              <CachedImage src={paramIcon} alt="Parameter" className="icon" />
            </div>
            {/* Ajoutez d'autres informations de profil ici */}
          </div>
        )}

        <ProfileModal
          isOpen={isProfileModalOpen}
          onRequestClose={() => setIsProfileModalOpen(false)}
          saveProfile={saveProfile}
          eth={eth}
          existingProfile={{ name, eth, bio }}
        />
      </div>
      {notification && <div className="alert">{notification}</div>}
    </div>
  );
};

export default Dashboard;
