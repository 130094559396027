import React from 'react';

function ActiveBets({ activeBets }) {
  return (
    <div id="active-bets">
      {activeBets.map(activeBet => (
        <div className="bet-item" key={activeBet.id}>
          <p><strong>Pari multiple</strong></p>
          <p>Nombre de sélections: {activeBet.bets.length}</p>
          <p>Montant: {activeBet.amount}€</p>
          <p>Gain potentiel: {activeBet.potentialWin}€</p>
        </div>
      ))}
    </div>
  );
}

export default ActiveBets;
