import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../axiosConfig";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { Web3Provider } from "@ethersproject/providers";
import { Contract } from "@ethersproject/contracts";
import abiTournament from "./ABI/Tournament";
import "../styles/TeamModal.css";
import ProfileCard from "./Profile/ProfileCard";

const TeamGestionModal = ({ isOpen, onRequestClose, eth }) => {
  const [team, setTeam] = useState(null);
  const [teamName, setTeamName] = useState("");
  const [inviteMemberAddress, setInviteMemberAddress] = useState("");
  const [inviteMemberRole, setInviteMemberRole] = useState("member");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [teamDescription, setTeamDescription] = useState("");
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [isTeamNameValid, setIsTeamNameValid] = useState(true);
  const [selectedMember, setSelectedMember] = useState(null); // State for selected member
  const [, setShowProfileModal] = useState(false); // State for showing profile modal
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });
  const modalRef = useRef(null);

  const provider = new Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const TOURNAMENT_ADDRESS = process.env.REACT_APP_TOURNAMENT_CONTRACT_ADDRESS;

  const tournamentContract = TOURNAMENT_ADDRESS
    ? new Contract(TOURNAMENT_ADDRESS, abiTournament, signer)
    : null;

  const roles = ["member", "coach"]; // List of available roles

  const validateTeamNameFormat = (name) => {
    return (
      name.length >= 3 && name.length <= 20 && /^[a-zA-Z0-9 ]+$/.test(name)
    );
  };

  const handleCreateTeam = async () => {
    if (!validateTeamNameFormat(teamName)) {
      setIsTeamNameValid(false);
      setErrorMessage(
        "Le nom de l'équipe doit avoir entre 3 et 20 caractères et ne contenir que des lettres, des chiffres et des espaces."
      );
      return;
    }

    try {
      await provider.send("eth_requestAccounts", []);
      const accounts = await provider.listAccounts();
      const gasLimit = 3000000;

      console.log("Creating transaction object");
      const tx = {
        from: accounts[0],
        to: TOURNAMENT_ADDRESS,
        data: tournamentContract.interface.encodeFunctionData("registerTeam", [
          accounts[0],
        ]),
        gasLimit: gasLimit,
      };

      console.log("Sending transaction");
      const txResponse = await signer.sendTransaction(tx);
      console.log("Waiting for transaction to be mined");
      const receipt = await txResponse.wait();

      console.log("Transaction receipt:", receipt);
      if (receipt.status === 1) {
        const response = await axiosInstance.post("/api/teams", {
          name: teamName,
          description: teamDescription,
          captainId: accounts[0],
          address: accounts[0],
        });
        const team = response.data.team;

        toast.success("Équipe créée avec succès!");
        setTeam(team);
        setTeamName("");
        setTeamDescription("");
        setErrorMessage("");
        onRequestClose();
      } else {
        throw new Error("La transaction a échoué");
      }
    } catch (error) {
      console.error("Erreur lors de la création de l'équipe:", error);
      console.log("Détails de l'erreur:", error.transactionHash, error.receipt);
      setErrorMessage(
        error.response?.data?.message ||
          "Erreur lors de la création de l'équipe"
      );
    }
  };

  useEffect(() => {
    const checkIfTeamExists = async (eth) => {
      setLoading(true);
      try {
        console.log("Checking if team exists for user:", eth);
        const res = await axiosInstance.get(`/api/users/eth/${eth}`);
        console.log("User data:", res.data.team);
        if (res.data) {
          const teamRes = await axiosInstance.get(
            `/api/teams/${res.data.team}/members/${eth}`
          );
          console.log("User has a team:", teamRes.data);
          setTeam((prevTeam) =>
            prevTeam !== teamRes.data ? teamRes.data : prevTeam
          );
        } else {
          console.log("User does not have a team.");
          setTeam(null);
        }
      } catch (err) {
        console.error("Error checking if team exists:", err);
      } finally {
        setLoading(false);
      }
    };

    checkIfTeamExists(eth);
  }, [eth]);

  const handleInviteMember = async () => {
    try {
      console.log("Inviting member:", {
        from: eth,
        to: inviteMemberAddress,
        team: team._id,
        role: inviteMemberRole,
      });
      const response = await axiosInstance.post("/api/invitations/invite", {
        from: eth,
        to: inviteMemberAddress,
        team: team._id,
        role: inviteMemberRole,
      });
      toast.success("Membre invité avec succès!");
      setTeam((prevTeam) => ({
        ...prevTeam,
        members: response.data.team.members,
      }));
      setInviteMemberAddress("");
      setInviteMemberRole("member");
      setErrorMessage("");
    } catch (error) {
      console.error("Erreur lors de l'invitation du membre:", error.message);
      setErrorMessage(
        error.response?.data?.message || "Erreur lors de l'invitation du membre"
      );
    }
  };

  const handleLeaveTeam = async () => {
    try {
      await axiosInstance.post(`/api/teams/${team._id}/leave`, {
        eth,
      });
      toast.success("Vous avez quitté l'équipe.");
      setTeam(null);
    } catch (error) {
      console.error("Erreur lors de la sortie de l'équipe:", error.message);
      setErrorMessage("Erreur lors de la sortie de l'équipe");
    }
  };

  const handleLeaveTeamConfirmation = () => {
    if (window.confirm("Êtes-vous sûr de vouloir quitter l'équipe ?")) {
      handleLeaveTeam();
    }
  };

  const handleDeleteTeam = async () => {
    try {
      await axiosInstance.delete(`/api/teams/${team._id}`);
      toast.success("Équipe supprimée avec succès.");
      setTeam(null);
    } catch (error) {
      console.error(
        "Erreur lors de la suppression de l'équipe:",
        error.message
      );
      setErrorMessage("Erreur lors de la suppression de l'équipe");
    }
  };

  const handleEditDescription = () => {
    setIsEditingDescription(true);
  };

  const handleSaveDescription = async () => {
    try {
      await axiosInstance.put(`/api/teams/${team._id}/description`, {
        description: teamDescription,
      });
      setTeam((prevTeam) => ({
        ...prevTeam,
        description: teamDescription,
      }));
      setIsEditingDescription(false);
      toast.success("Description mise à jour avec succès.");
    } catch (error) {
      console.error(
        "Erreur lors de la mise à jour de la description:",
        error.message
      );
      setErrorMessage("Erreur lors de la mise à jour de la description");
    }
  };

  const handleMemberClick = (member, event) => {
    const modalRect = modalRef.current.getBoundingClientRect();
    const x = event.clientX - modalRect.left;
    const y = event.clientY - modalRect.top;

    setSelectedMember(member);
    setClickPosition({ x, y });
    setShowProfileModal(true);
  };

  const closeProfileModal = () => {
    setShowProfileModal(false);
    setSelectedMember(null);
  };

  return (
    <Modal
      show={isOpen}
      onHide={onRequestClose}
      centered
      className="custom-team-modal"
    >
      <Modal.Body ref={modalRef}>
        {loading ? (
          <Spinner animation="border" />
        ) : (
          <>
            <div className="team-management">
              {team ? (
                <>
                  <h3>Gestion de l'équipe</h3>

                  <div>
                    <strong>Description :</strong>
                    {isEditingDescription ? (
                      <div>
                        <Form.Control
                          as="textarea"
                          value={teamDescription}
                          onChange={(e) => setTeamDescription(e.target.value)}
                        />
                        {team.address === eth && (
                          <Button onClick={handleSaveDescription}>
                            Enregistrer
                          </Button>
                        )}
                      </div>
                    ) : (
                      <div>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                          {team.description}
                        </p>
                        {team.address === eth && (
                          <Button onClick={handleEditDescription}>
                            Modifier la description
                          </Button>
                        )}
                      </div>
                    )}
                  </div>

                  <ul className="team-members-list">
                    {team.members && team.members.length > 0 ? (
                      team.members.map(
                        (member) =>
                          // Add a check to ensure member.user is not null
                          member.user && (
                            <li
                              key={member.user._id}
                              className="team-member"
                              onClick={(e) => handleMemberClick(member.user, e)}
                            >
                              <span className="member-info">
                                <span>{member.user.name}</span>{" "}
                                <span>({member.role})</span>
                              </span>
                              {eth === member.user.eth && (
                                <Button
                                  variant="danger"
                                  onClick={handleLeaveTeamConfirmation}
                                  className="leave-button"
                                >
                                  Quitter
                                </Button>
                              )}
                            </li>
                          )
                      )
                    ) : (
                      <p>Aucun membre dans l'équipe.</p>
                    )}
                  </ul>

                  {team.address === eth && (
                    <>
                      <h4>Inviter un membre</h4>
                      <div className="invite-member-form-div">
                        <Form.Group
                          controlId="formInviteMember"
                          className="invite-member-form"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Adresse Ethereum"
                            value={inviteMemberAddress}
                            onChange={(e) =>
                              setInviteMemberAddress(e.target.value)
                            }
                          />
                          <Form.Control
                            as="select"
                            value={inviteMemberRole}
                            onChange={(e) =>
                              setInviteMemberRole(e.target.value)
                            }
                          >
                            {roles.map((role) => (
                              <option key={role} value={role}>
                                {role.charAt(0).toUpperCase() + role.slice(1)}
                              </option>
                            ))}
                          </Form.Control>
                        </Form.Group>
                        <Button
                          onClick={handleInviteMember}
                          className="invite-button"
                        >
                          Inviter
                        </Button>
                      </div>
                      <div className="delete-leave-button">
                        <Button
                          variant="danger"
                          onClick={handleDeleteTeam}
                          className="delete-team-button"
                        >
                          Supprimer l'équipe
                        </Button>
                      </div>
                    </>
                  )}
                </>
              ) : (
                <>
                  <h3>Créer une équipe</h3>
                  <Form.Group
                    controlId="formTeamName"
                    className="create-team-form"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Nom de l'équipe"
                      value={teamName}
                      onChange={(e) => {
                        setTeamName(e.target.value);
                        setIsTeamNameValid(
                          validateTeamNameFormat(e.target.value)
                        );
                      }}
                      isInvalid={!isTeamNameValid}
                    />
                    {!isTeamNameValid && (
                      <Form.Control.Feedback type="invalid">
                        Le nom de l'équipe doit avoir entre 3 et 20 caractères
                        et ne contenir que des lettres, des chiffres et des
                        espaces.
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Button
                    onClick={handleCreateTeam}
                    className="create-team-button"
                    disabled={!isTeamNameValid}
                  >
                    Créer l'équipe
                  </Button>
                </>
              )}
            </div>
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
            {console.log("clickPosition", clickPosition)}
            {selectedMember && (
              <div
                style={{
                  position: "absolute",
                  top: `${clickPosition.y}px`,
                  left: `${clickPosition.x}px`,
                  zIndex: 1000,
                }}
              >
                <ProfileCard
                  type="user"
                  id={selectedMember._id}
                  userId={eth}
                  onClose={closeProfileModal}
                />
              </div>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default TeamGestionModal;
