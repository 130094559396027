import React from "react";
import "../../App.css";

const RevolutionTech = () => {
  return (
    <div className="revolTechContainer">
      <div className="leftPartRevo">
        <h1>
          REVOLUTIONARY
          <br /> TECHNOLOGY
        </h1>
        <div className="contentKeyWord">
          <span className="keyWordRevo">EGALITY</span>
          <span className="keyWordRevo">TRANSPARENCY </span>
          <span className="keyWordRevo">FAIRPLAY</span>
          <span className="keyWordRevo">TRACEABILITY</span>
        </div>
        <p>We use blockchain technology<br/>
        guaranteeing security and decentralized transparency</p>
        <div className="profilsProPlayer">
            <div className="profilProPlayer"></div>
            <div className="profilProPlayer"></div>
            <div className="profilProPlayer"></div>
            <div className="profilProPlayer"></div>
            <div className="profilProPlayer"></div>
            <div className="profilProPlayer"></div>
        </div>
      </div>
      <div className="splineViewer">
      </div>
    </div>
  );
};

export default RevolutionTech;
