import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Invitations from "./Invitations";
import axiosInstance from "../../axiosConfig";
import "../../styles/NotificationButton.css";
import CachedImage from "../CachedImage";
import cloche from "../../assets/icons/Icon_notif.png";

const NotificationButton = ({ userId }) => {
  const [show, setShow] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [, setInvitations] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const fetchInvitations = async () => {
      try {
        const { data } = await axiosInstance.get(
          `/api/invitations/user/${userId}`
        );
        setInvitations(data);
        setNotificationCount(data.length);
      } catch (error) {
        console.error("Error fetching invitations:", error);
      }
    };

    fetchInvitations();

    const intervalId = setInterval(fetchInvitations, 5000); // Mise à jour toutes les 5 secondes

    return () => clearInterval(intervalId);
  }, [userId]);

  return (
    <>
      <button
        variant="primary"
        onClick={handleShow}
        className="notification-button"
      >
        <CachedImage src={cloche} alt="cloche" />
        <span
          className={`notification-badge ${notificationCount > 0 ? "new" : ""}`}
        >
          {notificationCount}
        </span>
      </button>

      <Modal
        isOpen={show}
        onRequestClose={handleClose}
        contentLabel="Notifications"
        className="notification-modal"
      >
        <h2>Notifications</h2>
        <Invitations userId={userId} />
      </Modal>
    </>
  );
};

export default NotificationButton;
