import React, { useState, useEffect, useCallback } from 'react';
import { getLiveStreams } from '../services/twitchService';

const TwitchLiveStreams = ({ channels, onLiveStatusChange }) => {
    const [liveStreams, setLiveStreams] = useState([]);

    const fetchLiveStreams = useCallback(async () => {
        try {
            console.log("Fetching live streams...");
            const streams = await getLiveStreams(channels);
            console.log("Fetched streams:", streams);
            setLiveStreams(streams);
            const isLive = streams.length > 0;
            console.log(`Calling onLiveStatusChange with: ${isLive}`);
            onLiveStatusChange(isLive);
        } catch (error) {
            console.error('Error fetching live streams:', error);
            onLiveStatusChange(false);  // Assume no live streams in case of error
        }
    }, [channels, onLiveStatusChange]);

    useEffect(() => {
        console.log("TwitchLiveStreams useEffect triggered");
        fetchLiveStreams();

        const intervalId = setInterval(fetchLiveStreams, 60000); // Check every minute

        return () => clearInterval(intervalId);
    }, [fetchLiveStreams]);

    console.log('Rendering TwitchLiveStreams. Current liveStreams:', liveStreams);

    return (
        <div className="twitch-live-streams">
            {liveStreams.length > 0 ? (
                liveStreams.map((stream) => (
                    <div key={stream.id} className="stream">
                        <iframe
                            src={`https://player.twitch.tv/?channel=${stream.user_login}&parent=${window.location.hostname}`}
                            height="400"
                            width="710"
                            allowFullScreen
                            title={`${stream.user_login} est en live.`}
                        ></iframe>
                    </div>
                ))
            ) : (
                <p>No Live</p>
            )}
        </div>
    );
};

export default React.memo(TwitchLiveStreams);