import React from 'react';
import Product from './Product';
import '../../styles/Boutique.module.css';

const Boutique = () => {

    return (
        <div className="App">
            <main>
                <section className="products">
                    <Product
                        imgSrc="https://rektesport.com/tshirt.jpg"
                        imgAlt="T-shirt Rekt Esport avec design futuriste orange et bleu"
                        title="T-shirt Rekt Esport Pro"
                        price="49.99"
                    />
                    <Product
                        imgSrc="https://rektesport.com/hoodie.jpg"
                        imgAlt="Sweat à capuche Rekt Esport avec motifs géométriques violet et orange"
                        title="Sweat à capuche Rekt Elite"
                        price="79.99"
                    />
                    <Product
                        imgSrc="https://rektesport.com/mousepad.jpg"
                        imgAlt="Tapis de souris Rekt Esport XXL avec design cyberpunk bleu et violet"
                        title="Tapis de souris Rekt Pro-Gamer"
                        price="34.99"
                    />
                    <Product
                        imgSrc="https://rektesport.com/cap.jpg"
                        imgAlt="Casquette Rekt Esport avec logo holographique orange et bleu"
                        title="Casquette Rekt Holographic"
                        price="39.99"
                    />
                </section>
            </main>
        </div>
    );
}

export default Boutique;