import React from 'react';
import '../../styles/Boutique.module.css';

const Product = ({ imgSrc, imgAlt, title, price }) => {
    const [buttonText, setButtonText] = React.useState('Ajouter au panier');

    const handleClick = () => {
        setButtonText('Ajouté !');
        setTimeout(() => {
            setButtonText('Ajouter au panier');
        }, 2000);
    };

    return (
        <div className="product">
            <img src={imgSrc} alt={imgAlt} width="280" height="250" />
            <h3>{title}</h3>
            <p>{price} €</p>
            <button className="btn" onClick={handleClick}>{buttonText}</button>
        </div>
    );
};

export default Product;
