const SearchBar = ({ searchTerm, onSearch, onFocus, onBlur }) => {

  const handleChange = (event) => {
    onSearch(event.target.value);
  };

  return (
    <div className="searchBar">
      <input
        type="text"
        value={searchTerm}
        onChange={handleChange}
        onFocus={onFocus}
        onBlur={onBlur}
        placeholder="Search what you want..."
      />
    </div>
  );
};

export default SearchBar;
