import React, { useState, useEffect, useRef, useCallback } from "react";
import axiosInstance from "../axiosConfig";
import { useWallet } from "../context/WalletContext";
import "../styles/ChatButton.css";

const ChatModal = ({ isOpen, onClose, chatType }) => {
  const { account } = useWallet();
  const [user, setUser] = useState(null);
  const [teamId, setTeamId] = useState(null);
  const [currentChat, setCurrentChat] = useState(chatType || "general");
  const [chatHistory, setChatHistory] = useState({
    general: [],
    clan: [],
    match: [],
  });
  const [newMessage, setNewMessage] = useState("");
  const chatEndRef = useRef(null);
  const [matchChats, setMatchChats] = useState([]);
  const [isVotingPanelOpen, setIsVotingPanelOpen] = useState(false);

  const scrollToBottom = useCallback(() => {
    const chatWindow = chatEndRef.current?.parentElement;
    if (chatWindow) {
      const { scrollTop, scrollHeight, clientHeight } = chatWindow;
      const isNearBottom = scrollHeight - scrollTop - clientHeight;

      if (isNearBottom || isOpen) {
        chatEndRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
      }
    }
  }, [isOpen]);

  const restoreChatState = () => {
    const savedChat = localStorage.getItem("currentChat");
    if (savedChat) {
      setCurrentChat(savedChat);
    }
  };

  useEffect(() => {
    restoreChatState();
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get(`/api/users/eth/${account}`);
        setUser(response.data);
        setTeamId(response.data.team);
      } catch (error) {
        console.error("Failed to fetch user", error);
      }
    };

    if (account) {
      fetchUser();
    }
  }, [account]);

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        let url;
        if (currentChat.startsWith("match-")) {
          const matchId = currentChat.split("-")[1];
          url = `/api/matches/match/${matchId}/chat?limit=100`;
        } else {
          url = `/api/chats/${currentChat}${
            currentChat === "clan" ? `/${teamId}` : ""
          }?limit=100`;
        }
        const response = await axiosInstance.get(url);
        setChatHistory((prevHistory) => ({
          ...prevHistory,
          [currentChat]: response.data.messages.map((msg) => (
            <div className="message" key={msg._id}>
              <span className="username">{msg.sender}</span>
              <span className="timestamp">
                {new Date(msg.timestamp).toLocaleTimeString()}
              </span>
              <div className="message-content">{msg.content}</div>
            </div>
          )),
        }));
      } catch (error) {
        console.error("Failed to fetch messages", error);
      }
    };

    if (isOpen) {
      fetchMessages();
    }

    const intervalId = setInterval(() => {
      if (isOpen) {
        fetchMessages();
      }
    }, 1000); // Polling every 5 seconds

    return () => clearInterval(intervalId);
  }, [currentChat, isOpen, teamId]);

  // useEffect(() => {
  //   if (isOpen) {
  //     const fetchMessages = async () => {
  //       try {
  //         let url;
  //         if (currentChat.startsWith("match-")) {
  //           const matchId = currentChat.split("-")[1];
  //           url = `/api/matches/match/${matchId}/chat?limit=100`;
  //         } else {
  //           url = `/api/chats/${currentChat}${
  //             currentChat === "clan" ? `/${teamId}` : ""
  //           }?limit=100`;
  //         }
  //         const response = await axiosInstance.get(url);
  //         setChatHistory((prevHistory) => ({
  //           ...prevHistory,
  //           [currentChat]: response.data.messages.map((msg) => (
  //             <div className="message" key={msg._id}>
  //               <span className="username">{msg.sender}</span>
  //               <span className="timestamp">
  //                 {new Date(msg.timestamp).toLocaleTimeString()}
  //               </span>
  //               <div className="message-content">{msg.content}</div>
  //             </div>
  //           )),
  //         }));
  //       } catch (error) {
  //         console.error("Failed to fetch messages", error);
  //       }
  //     };

  //     fetchMessages();
  //   }
  // }, [currentChat, isOpen, teamId]);

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory, scrollToBottom]);

  useEffect(() => {
    const fetchMatchChats = async () => {
      if (!teamId) return;

      try {
        const response = await axiosInstance.get(
          `/api/matches/teamMatches/${teamId}`
        );
        setMatchChats(response.data);
      } catch (error) {
        console.error("Failed to fetch match chats", error);
      }
    };

    fetchMatchChats();
  }, [teamId]);

  // useEffect(() => {
  //   if (isOpen && currentChat === "general") {
  //     const fetchGeneralChatMessages = async () => {
  //       try {
  //         const response = await axiosInstance.get(`/api/chats/general`);
  //         setChatHistory((prevHistory) => ({
  //           ...prevHistory,
  //           general: response.data.messages.map((msg) => (
  //             <div className="message" key={msg._id}>
  //               <span className="username">{msg.sender}</span>
  //               <span className="timestamp">
  //                 {new Date(msg.timestamp).toLocaleTimeString()}
  //               </span>
  //               <div className="message-content">{msg.content}</div>
  //             </div>
  //           )),
  //         }));
  //       } catch (error) {
  //         console.error("Failed to fetch general chat messages", error);
  //       }
  //     };
  //     fetchGeneralChatMessages();
  //   }
  // }, [isOpen, currentChat]);

  // useEffect(() => {
  //   if (teamId) {
  //     // Réinitialiser l'historique du chat lors du changement d'équipe
  //     setChatHistory({
  //       general: [],
  //       clan: [],
  //       match: [],
  //     });
  //     // Charger l'historique du chat d'équipe
  //     const fetchTeamChatMessages = async () => {
  //       try {
  //         const response = await axiosInstance.get(`/api/chats/clan/${teamId}`);
  //         setChatHistory((prevHistory) => ({
  //           ...prevHistory,
  //           clan: response.data.messages.map((msg) => (
  //             <div className="message" key={msg._id}>
  //               <span className="username">{msg.sender}</span>
  //               <span className="timestamp">
  //                 {new Date(msg.timestamp).toLocaleTimeString()}
  //               </span>
  //               <div className="message-content">{msg.content}</div>
  //             </div>
  //           )),
  //         }));
  //         scrollToBottom();
  //       } catch (error) {
  //         console.error("Failed to fetch team chat messages", error);
  //       }
  //     };
  //     fetchTeamChatMessages();
  //   }
  // }, [teamId, scrollToBottom]);

  const addMessage = (username, message, chat) => {
    const timestamp = new Date().toLocaleTimeString();
    const messageElement = (
      <div className="message" key={timestamp}>
        <span className="username">{username}</span>
        <span className="timestamp">{timestamp}</span>
        <div className="message-content">{message}</div>
      </div>
    );
    setChatHistory((prevHistory) => {
      const updatedChat = [...prevHistory[chat], messageElement].slice(-100);
      return {
        ...prevHistory,
        [chat]: updatedChat,
      };
    });
  };

  // Effet pour réinitialiser l'état lorsque l'utilisateur change de compte
  useEffect(() => {
    if (account) {
      setCurrentChat(chatType || "general");
      setChatHistory({
        general: [],
        clan: [],
        match: [],
      });
      setNewMessage("");
      //si l'utilisateur n'est pas dans une équipe, réinitialiser les chats de match
      if (!teamId) {
        setMatchChats([]);
      }
    }
  }, [account, chatType, teamId]);

  const switchChat = (chat) => {
    setIsVotingPanelOpen(false);
    setCurrentChat(chat);
    localStorage.setItem("currentChat", chat);
  };

  const sendMessage = async () => {
    if (newMessage.trim() && user) {
      let url;
      try {
        if (currentChat.startsWith("match-")) {
          const matchId = currentChat.split("-")[1];
          url = `/api/matches/match/${matchId}/chat`;
        } else {
          url = `/api/chats/${currentChat}${
            currentChat === "clan" ? `/${teamId}` : ""
          }`;
        }

        // Utilisation d'axios pour envoyer le message
        await axiosInstance.post(url, {
          username: user.name,
          text: newMessage,
        });

        addMessage(user.name, newMessage, currentChat);
        setNewMessage("");
        scrollToBottom();
      } catch (error) {
        console.error("Failed to send message", error);
      }
    }
  };

  const [isExiting, setIsExiting] = useState(false);

  const toggleVotingPanel = () => {
    if (isVotingPanelOpen) {
      setIsExiting(true);
      setTimeout(() => {
        setIsVotingPanelOpen(false);
        setIsExiting(false);
      }, 300);
    } else {
      setIsVotingPanelOpen(true);
    }
  };

  const checkIfMatchIsOver = async (matchId) => {
    try {
      const response = await axiosInstance.get(`/api/matches/${matchId}`);
      if (response.data.winner) {
        setCurrentChat("general");
        alert("Le match est terminé. Le chat a été supprimé.");
      }
    } catch (error) {
      console.error("Failed to check match status", error);
    }
  };

  const handleVote = async (vote) => {
    const teamId = user.team; // Assuming user.team holds the team ID
    const userId = user._id;

    try {
      const matchId = currentChat.split("-")[1];
      console.log(
        `Submitting vote. Team ID: ${teamId}, User ID: ${userId}, Match ID: ${matchId}, Vote: ${vote}`
      );
      const response = await axiosInstance.post(
        `/api/matches/${matchId}/vote`,
        {
          teamId,
          userId,
          vote,
        }
      );

      if (response.data.status === "pending") {
        alert(
          "Le vote a été enregistré. En attente du vote de l'autre équipe."
        );
      } else if (response.data.status === "disagreement") {
        alert("Il y a un désaccord. Veuillez fournir une preuve photo.");
      } else if (response.data.status === "complete") {
        alert("Le match est terminé. Le gagnant a été déterminé.");
        await checkIfMatchIsOver(matchId);
      }
    } catch (error) {
      console.error("Failed to submit vote", error);
      alert("Erreur lors de l'envoi du vote.");
    }

    setIsExiting(true);
    setTimeout(() => {
      setIsVotingPanelOpen(false);
      setIsExiting(false);
    }, 300);
  };

  return (
    <div className={`modal ${isOpen ? "open" : ""}`}>
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <div className="chat-container">
          <div className="sidebar">
            <div className="chat-selector">
              <button
                className={`chat-button ${
                  currentChat === "general" ? "active" : ""
                }`}
                onClick={() => switchChat("general")}
              >
                Général
              </button>
              {teamId && (
                <button
                  className={`chat-button ${
                    currentChat === "clan" ? "active" : ""
                  }`}
                  onClick={() => switchChat("clan")}
                >
                  Equipe
                </button>
              )}
              {matchChats.map((chat) => (
                <button
                  key={chat._id}
                  className={`chat-button ${
                    currentChat === `match-${chat.matchId}` ? "active" : ""
                  }`}
                  onClick={() => switchChat(`match-${chat.matchId}`)}
                >
                  {chat.roundNumber === 1
                    ? "Round 1"
                    : chat.roundNumber === 2
                    ? "Round 2"
                    : "Finale"}{" "}
                  - {chat.teams.map((team) => team.name).join(" vs ")}
                </button>
              ))}
            </div>
          </div>
          <div
            className={`main-content ${
              isVotingPanelOpen ? "with-voting-panel" : ""
            }`}
          >
            <div
              className={`chat-window ${
                isVotingPanelOpen ? "with-voting-panel" : ""
              }`}
            >
              {chatHistory[currentChat]}
              <div ref={chatEndRef} />
            </div>
            <div className="chat-input">
              {isVotingPanelOpen && (
                <div className={`voting-panel ${isExiting ? "exiting" : ""}`}>
                  <button onClick={() => handleVote("win")}>Win</button>
                  <button onClick={() => handleVote("lose")}>Lose</button>
                </div>
              )}
              <input
                type="text"
                id="message-input"
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && sendMessage()}
                placeholder="Tapez votre message..."
              />
              {currentChat.startsWith("match-") && (
                <button id="vote-button" onClick={toggleVotingPanel}>
                  Voter
                </button>
              )}
              <button id="send-button" onClick={sendMessage}>
                Envoyer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatModal;
