import React, { useState } from 'react';
import MatchCard from './MatchCard';
import SelectedBets from './SelectedBets';
import ActiveBets from './ActiveBets';
import GameTabs from './GameTabs';
import styles from '../../styles/Bet.module.css';

const matches = [
    {
        id: 1,
        game: 'lol',
        title: 'LEC Summer Split',
        time: 'Aujourd\'hui, 20:00',
        team1: { name: 'G2 Esports', logo: 'https://esportsbetting.fr/images/teams/g2.png' },
        team2: { name: 'Fnatic', logo: 'https://esportsbetting.fr/images/teams/fnatic.png' },
        odds: { team1: 1.75, team2: 2.10 }
      },
      {
        id: 2,
        game: 'csgo',
        title: 'BLAST Premier',
        time: 'Demain, 18:30',
        team1: { name: 'Natus Vincere', logo: 'https://esportsbetting.fr/images/teams/navi.png' },
        team2: { name: 'Team Vitality', logo: 'https://esportsbetting.fr/images/teams/vitality.png' },
        odds: { team1: 1.90, team2: 1.95 }
      },
      {
        id: 3,
        game: 'dota2',
        title: 'The International',
        time: '03/08, 15:00',
        team1: { name: 'OG', logo: 'https://esportsbetting.fr/images/teams/og.png' },
        team2: { name: 'Team Secret', logo: 'https://esportsbetting.fr/images/teams/secret.png' },
        odds: { team1: 2.25, team2: 1.65 }
      },
      {
        id: 4,
        game: 'valorant',
        title: 'VCT Masters',
        time: '04/08, 19:00',
        team1: { name: 'Sentinels', logo: 'https://esportsbetting.fr/images/teams/sentinels.png' },
        team2: { name: 'Fnatic', logo: 'https://esportsbetting.fr/images/teams/fnatic.png' },
        odds: { team1: 1.80, team2: 2.05 }
      }
];

function Bet() {
  const [selectedBets, setSelectedBets] = useState([]);
  const [activeBets, setActiveBets] = useState([]);
  const [betAmount, setBetAmount] = useState('');
  const [activeTab, setActiveTab] = useState('all');

  const handleSelectBet = (matchId, team, odd) => {
    setSelectedBets(prevBets => {
      const matchIndex = prevBets.findIndex(bet => bet.matchId === matchId);
      if (matchIndex !== -1) {
        const newBets = [...prevBets];
        newBets[matchIndex] = { matchId, team, odd };
        return newBets;
      } else {
        return [...prevBets, { matchId, team, odd }];
      }
    });
  };

  const handlePlaceBet = () => {
    if (selectedBets.length > 0 && betAmount) {
      const amount = parseFloat(betAmount);
      let totalOdd = 1;
      selectedBets.forEach(bet => {
        totalOdd *= parseFloat(bet.odd);
      });
      const potentialWin = (amount * totalOdd).toFixed(2);

      setActiveBets(prevBets => [
        ...prevBets,
        {
          id: Date.now(),
          bets: selectedBets,
          amount,
          potentialWin
        }
      ]);

      alert(`Pari multiple placé avec succès !
      Nombre de sélections: ${selectedBets.length}
      Montant: ${amount}€
      Gain potentiel: ${potentialWin}€`);

      setSelectedBets([]);
      setBetAmount('');
    } else {
      alert('Veuillez sélectionner au moins un pari et entrer un montant.');
    }
  };

  const filteredMatches = matches.filter(match => activeTab === 'all' || match.game === activeTab);

  return (
    <div id="root">
    <div className={styles.app}>
      <main className={styles.container}>
        <div className={styles.mainContent}>
          <section className={styles.matchesSection}>
            <GameTabs activeTab={activeTab} onTabClick={setActiveTab} />
            <div id="matches-container">
              {filteredMatches.map(match => (
                <MatchCard key={match.id} match={match} onSelectBet={handleSelectBet} />
              ))}
            </div>
          </section>
          <section className={styles.betslipSection}>
            <div id={styles.betslip}>
              <h2>Mon pari</h2>
              <SelectedBets selectedBets={selectedBets} matches={matches} />
              <div className={styles.betInput}>
                <input
                  type="number"
                  id="bet-amount"
                  value={betAmount}
                  onChange={e => setBetAmount(e.target.value)}
                  placeholder="Montant du pari"
                />
                <button id={styles.placeBet} onClick={handlePlaceBet}>Placer le pari</button>
              </div>
            </div>
            <div id={styles.myBets}>
              <h2>Mes paris en cours</h2>
              <ActiveBets activeBets={activeBets} />
            </div>
          </section>
        </div>
      </main>
    </div>
    <footer className={styles.footer}>
      <p>&copy; 2023 EsportsBets. Tous droits réservés. Jouez responsablement. 18+</p>
    </footer>
  </div>
  );
}

export default Bet;
