import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosConfig";
import "../../styles/ModalProfile.css";
import { toast } from 'react-toastify';

const Profile = ({ type, id, userId }) => {
  const [profile, setProfile] = useState(null);
  const [teamDetails, setTeamDetails] = useState(null);
  const [tournamentNames, setTournamentNames] = useState({});

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const { data } = await axiosInstance.get(`/api/profile/${type}/${id}`);
        setProfile(data);
      } catch (error) {
        console.error("Error fetching profile", error);
      }
    };
    fetchProfile();
  }, [type, id]);

  useEffect(() => {
    if (profile?.team) {
      const fetchTeamDetails = async () => {
        try {
          const { data } = await axiosInstance.get(
            `/api/teams/${profile.team}`
          );
          setTeamDetails(data);
          console.log("Team details fetched", data);
        } catch (error) {
          console.error("Error fetching team details", error);
        }
      };
      fetchTeamDetails();
    }
  }, [profile]);

  useEffect(() => {
    if (profile?.tournaments) {
      const fetchTournamentNames = async () => {
        try {
          const tournamentIds = profile.tournaments.map(
            (tournament) => tournament.tournamentId
          );
          const names = {};

          // Utilisation de Promise.all pour exécuter les requêtes en parallèle
          await Promise.all(
            tournamentIds.map(async (id) => {
              const { data } = await axiosInstance.get(
                `/api/tournaments/${id}`
              );
              if (data && data.name) {
                names[id] = data.name;
              } else {
                names[id] = null;
              }
            })
          );

          setTournamentNames(names);
        } catch (error) {
          console.error("Error fetching tournament names", error);
        }
      };
      fetchTournamentNames();
    }
  }, [profile]);

  const handleJoinTeam = async () => {
    try {
      console.log("Requesting to join team", profile._id, 'by user', userId);
      await axiosInstance.post(`/api/invitations/request`, { userId, teamId: profile._id });
      toast.success("Request to join team sent successfully");
    } catch (error) {
      toast.error("Failed to send request to join team");
      console.error("Error requesting to join team", error);
    }
  };

  if (!profile) return <div>Loading...</div>;

  return (
    <div className="profileContainer2">
      <h2>{profile.name}</h2>
      <p>{profile.eth}</p>
      {profile.bio && <p className="descriptionContainer">{profile.bio}</p>}
      {type === "team" ? (
        <div>
          <p
            style={{ whiteSpace: "pre-wrap" }}
            className="descriptionContainer"
          >
            {profile.description}
          </p>
          <button onClick={handleJoinTeam} className="joinButton">Join</button>
        </div>
      ) : (
        <>
          <p>Team: {teamDetails ? teamDetails.name : "No team"}</p>
          <p>
            Tournament Elo:{" "}
            {profile.tournaments.tournamentElo?.tournamentElo ?? 0}
          </p>
          <p>Bet Elo: {profile.betElo}</p>
          <h3>Tournois gagnés</h3>
          <ul>
            {profile.tournaments.map((tournament) => (
              <li key={tournament.tournamentId}>
                {tournamentNames[tournament.tournamentId]}
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};

export default Profile;
