import React, { useEffect, useState } from "react";
import axiosInstance from "../axiosConfig";
import { useWallet } from "../context/WalletContext";
import chat from "../chat.svg";
import styles from "../styles/TournamentList.module.css";
import TeamModal from "./TeamModal";
import Modal from "react-modal";
import TournamentBracket from "./TournamentBracket";
import ChatModal from "./ChatModal";

const TournamentsList = ({ game }) => {
  const { account } = useWallet();
  const [notification] = useState();
  const [tournaments, setTournaments] = useState([]);
  const [registrations, setRegistrations] = useState({});
  const [timeLeft, setTimeLeft] = useState({});
  const [isTeamModalOpen, setIsTeamModalOpen] = useState(false);
  const [isBracketModalOpen, setIsBracketModalOpen] = useState(false);
  const [selectedTournament, setSelectedTournament] = useState(null);

  useEffect(() => {
    const fetchTournaments = async () => {
      try {
        const response = await axiosInstance.get(
          "/api/tournaments"
        );
        setTournaments(response.data);
        const deadlines = response.data.reduce((acc, tournament) => {
          const deadline = new Date(tournament.date).getTime();
          const now = new Date().getTime();
          acc[tournament._id] = deadline - now;
          return acc;
        }, {});
        setTimeLeft(deadlines);

        const userResponse = await axiosInstance.get(
          `/api/users/eth/${account}`
        );
        const userData = userResponse.data;

        const registrationsStatus = await Promise.all(
          response.data.map(async (tournament) => {
            const registrationResponse = await axiosInstance.get(
              `/api/tournaments/${tournament._id}/teams/${userData.team}`,
              {
                params: {
                  address: userData.eth,
                },
              }
            );
            return {
              tournamentId: tournament._id,
              isRegistered: registrationResponse.data.isRegistered,
            };
          })
        );

        const registrationsMap = registrationsStatus.reduce((acc, reg) => {
          acc[reg.tournamentId] = reg.isRegistered;
          return acc;
        }, {});
        setRegistrations(registrationsMap);
      } catch (error) {
        console.error("Error fetching tournaments:", error);
      }
    };

    fetchTournaments();
  }, [account, game]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft((prevTimeLeft) => {
        const newTimeLeft = { ...prevTimeLeft };
        Object.keys(newTimeLeft).forEach((key) => {
          if (newTimeLeft[key] > 0) {
            newTimeLeft[key] -= 1000;
          }
        });
        return newTimeLeft;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const checkAndCloseRegistration = async () => {
      try {
        for (const tournament of tournaments) {
          const now = new Date().getTime();
          const tournamentDate = new Date(tournament.date).getTime();

          if (
            (now >= tournamentDate ||
              tournament.teams.length >= tournament.maxTeams) &&
            !tournament.isRegistrationClosed
          ) {
            console.log(`Closing registration for tournament:`, tournament);
            await axiosInstance.post(
              `/api/tournaments/${tournament._id}/close-registration`
            );

            // Rafraîchir la liste des tournois après fermeture
            const response = await axiosInstance.get(
              "/api/tournaments"
            );
            setTournaments(response.data);
          }
        }
      } catch (error) {
        console.error("Error closing registration:", error);
      }
    };

    if (tournaments.length > 0) {
      checkAndCloseRegistration();
    }
  }, [tournaments]);

  const [isChatModalOpen, setIsChatModalOpen] = useState(false);
  const [currentChatType, setCurrentChatType] = useState("general");

  const openChatModal = () => {
    setCurrentChatType(currentChatType);
    setIsChatModalOpen(true);
  };

  const openTeamModal = (tournament) => {
    setSelectedTournament(tournament);
    setIsTeamModalOpen(true);
  };

  const closeTeamModal = () => {
    setIsTeamModalOpen(false);
    setSelectedTournament(null);
  };

  const openBracketModal = (tournament) => {
    if (!tournament || !tournament._id) {
      console.error("Invalid tournament data");
      return;
    }
    setSelectedTournament(tournament);
    setIsBracketModalOpen(true);
  };

  const closeBracketModal = () => {
    setIsBracketModalOpen(false);
    setSelectedTournament(null);
  };

  const filteredTournaments = tournaments.filter(
    (tournament) =>
      tournament.game === game &&
      (!tournament.isRegistrationClosed || registrations[tournament._id])
  );

  const formatTimeLeft = (time, tournament) => {
    if (time <= 0 || tournament.isRegistrationClosed) return "Closed";
    const days = Math.floor(time / (1000 * 60 * 60 * 24));
    const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((time % (1000 * 60)) / 1000);
    return `${days}j ${hours}h ${minutes}m ${seconds}s`;
  };

  // Styles personnalisés pour le contenu de la modal et l'overlay
  const customStyles = {
    content: {
      display: "flex",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      width: "60%", // Largeur du contenu
      height: "70%", // Hauteur du contenu
      justifyContent: "center",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      color: "white", // Couleur du texte du contenu
      backgroundColor: "rgba(30, 30, 30)", // Couleur de fond du contenu
      padding: "20px", // Padding du contenu
      border: "none", // Bordure du contenu
      borderRadius: "5px", // Rayon de bordure du contenu
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.75)", // Couleur de fond de l'overlay
      zIndex: "1000", // Niveau de z-index de l'overlay
    },
  };

  return (
    <div className={styles.tournamentsList}>
      <div className={styles.tournamentsContainer}>
        {filteredTournaments.map((tournament) => (
          <div
            key={tournament._id}
            className={styles.tournamentItem}
            style={{
              backgroundImage: tournament.backgroundImage,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <div className={styles.overlay}></div>
            <div className={styles.tournamentTitle}>
              <h2>{tournament.name}</h2>
              <div className={styles.tournamentInfo}>
                <p>Cashprize: {tournament.cashprize} REKT</p>
                <p>
                  Inscriptions:{" "}
                  {formatTimeLeft(timeLeft[tournament._id], tournament)}
                </p>
                {registrations[tournament._id] ? (
                  <div>
                    {tournament.matches.map((match, index) => (
                      <button
                        key={`${match.matchId}-${index}`}
                        className={styles.seed}
                        onClick={() => openChatModal()}
                      >
                        <img
                          src={chat}
                          alt="Copy icon"
                          className={styles.copySeed}
                        />
                      </button>
                    ))}
                    <button
                      className={styles.bracketButton}
                      onClick={() => openBracketModal(tournament)}
                    >
                      Bracket
                    </button>
                  </div>
                ) : (
                  <div className={styles.entryFee}>
                    {!tournament.isRegistrationClosed &&
                      !registrations[tournament._id] && (
                        <button
                          className={styles.entryFeeButton}
                          onClick={() => openTeamModal(tournament)}
                        >
                          {tournament.entryFee}
                        </button>
                      )}
                  </div>
                )}
              </div>
              {notification && (
                <div className={styles.alert}>{notification}</div>
              )}
            </div>
          </div>
        ))}
      </div>
      {isChatModalOpen && (
        <ChatModal
          isOpen={isChatModalOpen}
          onClose={() => setIsChatModalOpen(false)}
          chatType={currentChatType}
        />
      )}
      {isTeamModalOpen && (
        <TeamModal
          isOpen={isTeamModalOpen}
          onRequestClose={closeTeamModal}
          eth={account}
          tournament={selectedTournament}
        />
      )}
      {isBracketModalOpen && selectedTournament && selectedTournament._id && (
        <Modal
          isOpen={isBracketModalOpen}
          onRequestClose={closeBracketModal}
          contentLabel="Tournament Bracket"
          style={customStyles}
        >
          <TournamentBracket tournamentId={selectedTournament._id} />
        </Modal>
      )}
    </div>
  );
};

export default TournamentsList;
