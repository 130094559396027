import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosConfig";
import { Modal, Button, Form, Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { Web3Provider } from "@ethersproject/providers";
import { BigNumber } from "@ethersproject/bignumber";
import { parseUnits } from "@ethersproject/units";
import { Contract } from "@ethersproject/contracts";
import gameSettings from "./config/gameSettings";
import abiTournament from "./ABI/Tournament";
import abiRekt from "./ABI/Rekt";
import "../styles/TeamModal.css";

const TeamModal = ({ isOpen, onRequestClose, eth, tournament }) => {
  const [team, setTeam] = useState(null);
  const [teamName, setTeamName] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [isRegistering, setIsRegistering] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [teamDescription, setTeamDescription] = useState("");
  const [isTeamNameValid, setIsTeamNameValid] = useState(true);

  const provider = new Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const TOURNAMENT_ADDRESS = process.env.REACT_APP_TOURNAMENT_CONTRACT_ADDRESS;
  const REKT_ADDRESS = process.env.REACT_APP_REKT_CONTRACT_ADDRESS;

  const tournamentContract = TOURNAMENT_ADDRESS
    ? new Contract(TOURNAMENT_ADDRESS, abiTournament, signer)
    : null;
  const rektContract = REKT_ADDRESS
    ? new Contract(REKT_ADDRESS, abiRekt, signer)
    : null;

  const validateTeamNameFormat = (name) => {
    return (
      name.length >= 3 && name.length <= 20 && /^[a-zA-Z0-9 ]+$/.test(name)
    );
  };

  const handleCreateTeam = async () => {
    if (!validateTeamNameFormat(teamName)) {
      setIsTeamNameValid(false);
      setErrorMessage(
        "Le nom de l'équipe doit avoir entre 3 et 20 caractères et ne contenir que des lettres, des chiffres et des espaces."
      );
      return;
    }

    try {
      await provider.send("eth_requestAccounts", []);
      const accounts = await provider.listAccounts();
      const gasLimit = 3000000;

      console.log("Creating transaction object");
      const tx = {
        from: accounts[0],
        to: TOURNAMENT_ADDRESS,
        data: tournamentContract.interface.encodeFunctionData("registerTeam", [
          accounts[0],
        ]),
        gasLimit: gasLimit,
      };

      console.log("Sending transaction");
      const txResponse = await signer.sendTransaction(tx);
      console.log("Waiting for transaction to be mined");
      const receipt = await txResponse.wait();

      console.log("Transaction receipt:", receipt);
      if (receipt.status === 1) {
        const response = await axiosInstance.post("/api/teams", {
          name: teamName,
          description: teamDescription,
          captainId: accounts[0],
          address: accounts[0],
        });
        const team = response.data.team;

        toast.success("Équipe créée avec succès!");
        setTeam(team);
        setTeamName("");
        setTeamDescription("");
        setErrorMessage("");
        onRequestClose();
      } else {
        throw new Error("La transaction a échoué");
      }
    } catch (error) {
      console.error("Erreur lors de la création de l'équipe:", error);
      console.log("Détails de l'erreur:", error.transactionHash, error.receipt);
      setErrorMessage(
        error.response?.data?.message ||
          "Erreur lors de la création de l'équipe"
      );
    }
  };

  useEffect(() => {
    const checkIfTeamExists = async (eth) => {
      setLoading(true);
      try {
        console.log("Checking if team exists for user:", eth);
        const res = await axiosInstance.get(`/api/users/eth/${eth}`);
        console.log("User data:", res.data.team);
        if (res.data) {
          const teamRes = await axiosInstance.get(
            `/api/teams/${res.data.team}/members/${eth}`
          );
          console.log("User has a team:", teamRes.data);
          setTeam((prevTeam) =>
            prevTeam !== teamRes.data ? teamRes.data : prevTeam
          );
        } else {
          console.log("User does not have a team.");
          setTeam(null);
        }
      } catch (err) {
        console.error("Error checking if team exists:", err);
      } finally {
        setLoading(false);
      }
    };

    checkIfTeamExists(eth);
  }, [eth]);

  const handleMemberSelect = (memberId) => {
    const selectedMember = team.members.find(
      (member) => member.user._id === memberId
    );
    const isCoachSelected = selectedMembers.some(
      (memberId) =>
        team.members.find((member) => member.user._id === memberId).role ===
        "coach"
    );

    if (
      selectedMember.role === "coach" &&
      isCoachSelected &&
      !selectedMembers.includes(memberId)
    ) {
      alert("Il ne peut y avoir qu'un seul coach par équipe.");
      return;
    }

    setSelectedMembers((prev) => {
      if (prev.includes(memberId)) {
        return prev.filter((id) => id !== memberId);
      } else {
        return [...prev, memberId];
      }
    });
  };

  const handleRegister = async (tournament) => {
    const gameKey = tournament.game.toLowerCase().replace(/\s+/g, "");
    const settings = gameSettings[gameKey];
    if (!window.ethereum) {
      alert("Vous devez vous connecter à un wallet");
      return;
    }

    if (!tournament || !tournament.game) {
      console.error("Données du tournoi incorrectes:", tournament);
      alert("Les données du tournoi sont manquantes ou incorrectes.");
      return;
    }

    if (selectedMembers.length === 0) {
      alert(
        `Vous devez sélectionner au moins ${settings.minSize} membre(s) pour vous inscrire.`
      );
      return;
    }

    try {
      await provider.send("eth_requestAccounts", []);
      const accounts = await provider.listAccounts();
      const teamResponse = await axiosInstance.get(
        `/api/teams/${team._id}/members`
      );
      const teamData = teamResponse.data;

      if (!teamData || !teamData.members) {
        alert(
          "Votre équipe n'est pas configurée correctement. Veuillez vous assurer que votre équipe est inscrite."
        );
        return;
      }

      if (!settings) {
        alert("Paramètres de jeu invalides");
        return;
      }

      const selectedMembersData = teamData.members.filter((member) =>
        selectedMembers.includes(member.user._id)
      );

      if (!selectedMembersData) {
        alert("Aucun membre sélectionné");
        return;
      }

      const coachCount =
        selectedMembersData.filter(
          (m) => m.role === "coach" || m.role === "captain"
        ).length || 0;
      const memberCount = selectedMembersData.length;
      const totalMembers = memberCount + coachCount;

      if (!settings) {
        alert("Invalid game settings");
        return;
      }

      if (memberCount < settings.minSize) {
        alert(
          `You need at least ${settings.minSize} members to register for this tournament.`
        );
        return;
      }

      if (coachCount > 1) {
        alert("Team cannot have more than one coach.");
        return;
      }

      if (coachCount === 1 && totalMembers > settings.maxSizeWithCoach) {
        alert(
          `Team cannot have more than ${settings.maxSizeWithCoach} members with a coach.`
        );
        return;
      }

      if (coachCount === 0 && memberCount > settings.maxSizeWithoutCoach) {
        alert(
          `Team cannot have more than ${settings.maxSizeWithoutCoach} members without a coach.`
        );
        return;
      }

      setIsRegistering(true);

      if (!teamData._id) {
        console.error("ID de l'équipe manquant:", teamData);
        alert("ID de l'équipe manquant.");
        return;
      }

      const checkRegistrationResponse = await axiosInstance.get(
        `/api/tournaments/${tournament._id}/teams/${teamData._id}`,
        {
          params: {
            address: teamData.address,
          },
        }
      );

      if (checkRegistrationResponse.data.isRegistered) {
        alert("Votre équipe est déjà inscrite à ce tournoi.");
        return;
      }

      let formattedTournamentId;
      if (
        typeof tournament._id === "string" &&
        tournament._id.match(/^[0-9a-fA-F]+$/)
      ) {
        formattedTournamentId = BigNumber.from("0x" + tournament._id);
      } else {
        throw new Error("Format d'ID de tournoi invalide");
      }

      if (typeof tournament.entryFee === "undefined") {
        console.error(
          "Frais d'inscription manquants dans les données du tournoi:",
          tournament
        );
        alert("Les frais d'inscription du tournoi sont manquants.");
        return;
      }

      const entryFeeInRekt = parseUnits(tournament.entryFee.toString(), 18);
      const requiredAllowance = parseUnits("10000000", 18);
      const allowance = await rektContract.allowance(
        accounts[0],
        TOURNAMENT_ADDRESS
      );

      if (allowance.lt(entryFeeInRekt)) {
        const approveTx = await rektContract.approve(
          TOURNAMENT_ADDRESS,
          requiredAllowance,
          { from: accounts[0] }
        );
        await approveTx.wait();
      }

      if (tournamentContract) {
        const feeData = await provider.getFeeData();

        const gasLimit = 5000000; // Augmenter la limite de gaz
        const maxFeePerGas = feeData.maxFeePerGas.mul(3); // Tripler les frais maximum
        const maxPriorityFeePerGas = feeData.maxPriorityFeePerGas.mul(3);

        console.log("Sending transaction with parameters:", {
          formattedTournamentId: formattedTournamentId.toString(),
          entryFeeInRekt: entryFeeInRekt.toString(),
          gasLimit,
          maxFeePerGas: maxFeePerGas.toString(),
          maxPriorityFeePerGas: maxPriorityFeePerGas.toString()
        });

        const registerTx = await tournamentContract.registerForTournament(
          formattedTournamentId,
          entryFeeInRekt,
          {
            from: accounts[0],
            gasLimit: gasLimit,
            maxFeePerGas: maxFeePerGas,
            maxPriorityFeePerGas: maxPriorityFeePerGas,
          }
        );
        
        console.log("Transaction sent:", registerTx.hash);
        const receipt = await registerTx.wait();
        console.log("Transaction receipt:", receipt);

        if (receipt.status === 0) {
          throw new Error("La transaction a échoué sur la blockchain");
        }
      } else {
        console.error("tournamentContract is not initialized.");
        return;
      }

      await axiosInstance.post(
        `/api/tournaments/${tournament._id}/teams/register-team`,
        {
          address: teamData.address,
          teamId: teamData._id,
          members: selectedMembers,
        }
      );

      alert("Inscription réussie !");
      onRequestClose();
    } catch (error) {
      console.error("Détails complets de l'erreur:", error);

      if (error.data) {
        try {
          const decodedError = tournamentContract.interface.parseError(error.data);
          console.log("Decoded error:", decodedError);
        } catch (decodeError) {
          console.log("Unable to decode error:", decodeError);
        }
      }

      if (error.code === "TRANSACTION_REPLACED") {
        console.log("Transaction remplacée. Détails:", error.replacement);
      }

      // Vérifier si l'erreur contient des détails sur la transaction
      if (error.transaction) {
        console.log("Détails de la transaction:", error.transaction);
      }

      // Vérifier si l'erreur contient un reçu
      if (error.receipt) {
        console.log("Reçu de la transaction:", error.receipt);
      }

      if (error.response) {
        console.error("Erreur de réponse de l'API :", error.response.data);
      } else if (error.request) {
        console.error("Erreur de requête :", error.request);
      } else {
        console.error("Erreur :", error.message);
      }

      alert("Inscription impossible ! : " + error.message);
    } finally {
      setIsRegistering(false);
    }
  };

  return (
    <Modal
      show={isOpen}
      onHide={onRequestClose}
      centered
      className="custom-team-modal"
    >
      <Modal.Body>
        {loading ? (
          <Spinner animation="border" />
        ) : (
          <>
            <div className="team-management">
              {team ? (
                <>
                  <h3>Gestion de l'équipe</h3>

                  <ul className="select-members-list">
                    {team.members &&
                      team.members.map((member) => (
                        <li
                          key={member.user._id}
                          className={`select-member ${
                            selectedMembers.includes(member.user._id)
                              ? "selected"
                              : ""
                          }`}
                          onClick={() => handleMemberSelect(member.user._id)}
                        >
                          <input
                            type="checkbox"
                            id={member.user._id}
                            checked={selectedMembers.includes(member.user._id)}
                            onChange={() => handleMemberSelect(member.user._id)}
                            style={{ display: "none" }}
                          />
                          <label htmlFor={member.user._id}>
                            <span className="member-info">
                              <span>{member.user.name}</span>{" "}
                              <span>({member.role})</span>
                            </span>
                          </label>
                        </li>
                      ))}
                  </ul>
                  {tournament._id && (
                    <Button
                      onClick={() => handleRegister(tournament)}
                      disabled={isRegistering}
                      className="register-button"
                    >
                      Register
                    </Button>
                  )}
                </>
              ) : (
                <>
                  <h3>Créer une équipe</h3>
                  <Form.Group
                    controlId="formTeamName"
                    className="create-team-form"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Nom de l'équipe"
                      value={teamName}
                      onChange={(e) => {
                        setTeamName(e.target.value);
                        setIsTeamNameValid(
                          validateTeamNameFormat(e.target.value)
                        );
                      }}
                      isInvalid={!isTeamNameValid}
                    />
                    {!isTeamNameValid && (
                      <Form.Control.Feedback type="invalid">
                        Le nom de l'équipe doit avoir entre 3 et 20 caractères
                        et ne contenir que des lettres, des chiffres et des
                        espaces.
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Button
                    onClick={handleCreateTeam}
                    className="create-team-button"
                    disabled={!isTeamNameValid}
                  >
                    Créer l'équipe
                  </Button>
                </>
              )}
            </div>
            {errorMessage && (
              <div className="error-message">{errorMessage}</div>
            )}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default TeamModal;
