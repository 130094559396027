import React, { useEffect, useState } from 'react';
import axiosInstance from '../../axiosConfig';
import { toast } from 'react-toastify';

const Invitations = ({ userId }) => {
  const [invitations, setInvitations] = useState([]);
  const [joinRequests, setJoinRequests] = useState([]);

  useEffect(() => {
    const fetchInvitations = async () => {
        console.log('Fetching invitations for user:', userId);
      try {
        const { data } = await axiosInstance.get(`/api/invitations/user/${userId}`);
        console.log('Invitations:', data);
        setInvitations(data);
      } catch (error) {
        console.error('Error fetching invitations:', error);
      }
    };

    const fetchJoinRequests = async () => {
      console.log('Fetching join requests for user:', userId);
      try {
        const { data } = await axiosInstance.get(`/api/invitations/requests/${userId}`);
        console.log('Join Requests:', data);
        setJoinRequests(data);
      } catch (error) {
        console.error('Error fetching join requests:', error);
      }
    };

    if (userId) {
        fetchJoinRequests();
        fetchInvitations();
      }
  }, [userId]);

  const acceptJoinRequest = async (requestId, role) => {
    try {
      await axiosInstance.post(`/api/invitations/acceptRequest/${requestId}`, { role });
      setJoinRequests(joinRequests.filter(request => request._id !== requestId));
      toast.success("Join request accepted");
    } catch (error) {
      console.error('Error accepting join request:', error);
      toast.error('Failed to accept join request');
    }
  };

  const rejectJoinRequest = async (requestId) => {
    try {
      await axiosInstance.post(`/api/invitations/rejectRequest/${requestId}`);
      setJoinRequests(joinRequests.filter(request => request._id !== requestId));
      toast.success('Join request rejected');
    } catch (error) {
      console.error('Error rejecting join request:', error);
      toast.error('Failed to reject join request');
    }
  };

  const acceptInvitation = async (invitationId) => {
    try {
      const invitation = invitations.find(inv => inv._id === invitationId);
      if (!invitation) {
        toast.error('Invitation not found');
        return;
      }
      const role = invitation.role;
      await axiosInstance.post(`/api/invitations/accept/${invitationId}`, { role });
      setInvitations(invitations.filter(invitation => invitation._id !== invitationId));
      toast.success("Invitation accepted");
    } catch (error) {
      console.error('Error accepting invitation:', error);
      toast.error('Failed to accept invitation');
    }
  };

  const rejectInvitation = async (invitationId) => {
    try {
      await axiosInstance.post(`/api/invitations/reject/${invitationId}`);
      setInvitations(invitations.filter(invitation => invitation._id !== invitationId));
      toast.success('Invitation rejected');
    } catch (error) {
      console.error('Error rejecting invitation:', error);
      toast.error('Failed to reject invitation');
    }
  };

  return (
    <div>
      <ul className='invitationSchema'>
        {invitations.map(invitation => (
          <li key={invitation._id}>
            {invitation.from?.name || 'Unknown'} invited you to join {invitation.team?.name || 'Unknown Team'}
            <button onClick={() => acceptInvitation(invitation._id)} className='invitationButton'>Accept</button>
            <button onClick={() => rejectInvitation(invitation._id)} className='invitationButton'>Reject</button>
          </li>
        ))}
      </ul>
      <ul className='invitationSchema'>
        {joinRequests.map(request => (
          <li key={request._id}>
            {request.from?.name || 'Unknown'} requested to join {request.team?.name || 'Unknown Team'}
            <button onClick={() => acceptJoinRequest(request._id, 'member')} className='invitationButton'>Accept</button>
            <button onClick={() => rejectJoinRequest(request._id)} className='invitationButton'>Reject</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Invitations;
