import React from 'react';

function SelectedBets({ selectedBets, matches }) {
  return (
    <div id="selected-bets">
      {selectedBets.map(bet => {
        const match = matches.find(m => m.id === bet.matchId);
        const selectedTeam = bet.team === 1 ? match.team1 : match.team2;
        return (
          <div className="bet-item" key={bet.matchId}>
            <p><strong>{match.title}</strong></p>
            <p>Équipe: {selectedTeam.name}</p>
            <p>Cote: {bet.odd}</p>
          </div>
        );
      })}
    </div>
  );
}

export default SelectedBets;
