import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { ethers } from 'ethers';

const AdminRoute = ({ element: Component, allowedAddress, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAddress = async () => {
      if (window.ethereum) {
        try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const provider = new ethers.BrowserProvider(window.ethereum);
          const signer = await provider.getSigner();
          const address = await signer.getAddress();

          if (address.toLowerCase() === allowedAddress.toLowerCase()) {
            setIsAuthenticated(true);
          }
        } catch (error) {
          console.error("Error checking address:", error);
        }
      }
      setLoading(false);
    };

    checkAddress();
  }, [allowedAddress]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/" />;
};

export default AdminRoute;