import { useState, useEffect } from "react";

const CachedImage = ({ src, alt, className }) => {
    const [cachedSrc, setCachedSrc] = useState(src);
  
    useEffect(() => {
      const cacheImage = async () => {
        const cache = await caches.open('image-cache');
        const response = await cache.match(src);
  
        if (response) {
          setCachedSrc(URL.createObjectURL(await response.blob()));
        } else {
          fetch(src)
            .then(response => response.blob())
            .then(blob => {
              cache.put(src, new Response(blob));
              setCachedSrc(URL.createObjectURL(blob));
            })
            .catch(error => console.error("Failed to fetch and cache image", error));
        }
      };
  
      cacheImage();
    }, [src]);
  
    return <img src={cachedSrc} alt={alt} className={className}/>;
  };
  
  export default CachedImage;