import React from "react";
import BentoHomeView from "./BentoHomeView";
import backgroundImage from "../../assets/backgroundImage.jpg";
import CachedImage from "../CachedImage";
import RevolutionTech from "./RevolutionTech";
import Sponsors from "./Sponsors";

const Home = () => {

  return (
    <main className="App-main">
      <CachedImage
        src={backgroundImage}
        className="App-background"
        alt="background"
      />
      <div className="homeTitle">
        <h1>REKT</h1>
        <h2>DEFINE YOUR SKILLS</h2>
        <button className="knowMoreBtn">Learn more...</button>
      </div>
      <BentoHomeView />
      <RevolutionTech />
      <Sponsors />
    </main>
  );
};

export default Home;
