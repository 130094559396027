import AdminAddLeaderboard from "./AdminAddLeaderboard";
import AdminTournamentForm from "./AdminTournamentForm";
import "./AdminPanel.css";

const AdminPage = () => {

  return (
    <div className="AdminPanel">
      <AdminAddLeaderboard />
      <AdminTournamentForm />
    </div>
  );
};

export default AdminPage;
