import React from "react";

const Sponsors = () => {
  return (
    <>
      <div className="sponsorsContainer"></div>
      <div class="sponsorsWrapper">
        <div class="sponsorsContainer2">
          <div class="sponsorsDiv">
            <h2>SPONSOR</h2>
          </div>
          <div class="sponsorsDiv">
            <h2>SPONSOR</h2>
          </div>
          <div class="sponsorsDiv">
            <h2>SPONSOR</h2>
          </div>
          <div class="sponsorsDiv">
            <h2>SPONSOR</h2>
          </div>
          <div class="sponsorsDiv">
            <h2>SPONSOR</h2>
          </div>
          <div class="sponsorsDiv">
            <h2>SPONSOR</h2>
          </div>
        </div>
        <div class="sponsorsContainer2">
          <div class="sponsorsDiv">
            <h2>SPONSOR</h2>
          </div>
          <div class="sponsorsDiv">
            <h2>SPONSOR</h2>
          </div>
          <div class="sponsorsDiv">
            <h2>SPONSOR</h2>
          </div>
          <div class="sponsorsDiv">
            <h2>SPONSOR</h2>
          </div>
          <div class="sponsorsDiv">
            <h2>SPONSOR</h2>
          </div>
          <div class="sponsorsDiv">
            <h2>SPONSOR</h2>
          </div>
        </div>
      </div>
      <div className="gradientDiv"></div>
    </>
  );
};

export default Sponsors;
