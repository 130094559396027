import React, { useState } from "react";
import axiosInstance from "../../axiosConfig";
import "./AdminPanel.css";

const AdminAddLeaderboard = () => {
  const [game, setGame] = useState("valorant");
  const [notification, setNotification] = useState("");
  const [leaderboardData, setLeaderboardData] = useState({
    pseudo: "",
    tournamentElo: 0,
    betElo: 0,
    dexElo: 0,
    mpElo: 0,
    eth: "", // Inclure l'adresse Ethereum
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLeaderboardData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Si le champ modifié est l'adresse Ethereum, récupérer le pseudo
    if (name === "eth" && value) {
      fetchPseudo(value);
    }
  };

  const fetchPseudo = async (eth) => {
    try {
      const response = await axiosInstance.get(
        `/api/users/${eth}`
      );
      if (response.data) {
        setLeaderboardData((prev) => ({
          ...prev,
          pseudo: response.data.name,
        }));
      }
    } catch (error) {
      console.error("Erreur lors de la récupération du pseudo :", error);
      setLeaderboardData((prev) => ({
        ...prev,
        pseudo: "", // Réinitialiser le pseudo si l'utilisateur n'est pas trouvé
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post(
        `/api/leaderboards/${game}/players`,
        leaderboardData
      );
      setNotification("Joueur ajouté ou mis à jour avec succès !");
      setTimeout(() => {
        setNotification("");
      }, 2000);
    } catch (error) {
      console.error(
        "Erreur lors de l'ajout ou de la mise à jour du joueur :",
        error
      );
      setNotification("Erreur lors de l'ajout ou de la mise à jour du joueur");
      setTimeout(() => {
        setNotification("");
      }, 2000);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="admin-tournament-form">
      <h2>Data Leaderboard</h2>
      <select value={game} onChange={(e) => setGame(e.target.value)} required>
        <option value="valorant">Valorant</option>
        <option value="rocketleague">Rocket League</option>
        <option value="leagueoflegends">League of Legends</option>
        <option value="fortnite">Fortnite</option>
        <option value="olympusgame">Olympus Game</option>
      </select>
      <input
        type="text"
        name="eth"
        placeholder="Ethereum Address"
        value={leaderboardData.eth}
        onChange={handleChange}
        required
      />
      <input
        type="number"
        name="tournamentElo"
        placeholder="Elo Tournoi"
        value={leaderboardData.tournamentElo}
        onChange={handleChange}
      />
      <input
        type="number"
        name="betElo"
        placeholder="Elo Bet"
        value={leaderboardData.betElo}
        onChange={handleChange}
      />
      <input
        type="number"
        name="dexElo"
        placeholder="Elo Dex"
        value={leaderboardData.dexElo}
        onChange={handleChange}
      />
      <input
        type="number"
        name="mpElo"
        placeholder="Elo MP"
        value={leaderboardData.mpElo}
        onChange={handleChange}
      />
      <button type="submit">Ajouter au leaderboard</button>
      {notification && <div className="alert">{notification}</div>}
    </form>
  );
};

export default AdminAddLeaderboard;
