import React, { useEffect, useState } from "react";
import axiosInstance from "../../axiosConfig";
import "../../styles/ProfileCard.css"; // Assurez-vous que ce fichier CSS contient les styles nécessaires
import { toast } from 'react-toastify';

const ProfileCard = ({ type, id, userId, onClose }) => {
  const [profile, setProfile] = useState(null);
  const [teamDetails, setTeamDetails] = useState(null);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const { data } = await axiosInstance.get(`/api/profile/${type}/${id}`);
        setProfile(data);
      } catch (error) {
        console.error("Error fetching profile", error);
      }
    };
    fetchProfile();
  }, [type, id]);

  useEffect(() => {
    if (profile?.team) {
      const fetchTeamDetails = async () => {
        try {
          const { data } = await axiosInstance.get(`/api/teams/${profile.team}`);
          setTeamDetails(data);
        } catch (error) {
          console.error("Error fetching team details", error);
        }
      };
      fetchTeamDetails();
    }
  }, [profile]);

  const handleJoinTeam = async () => {
    try {
      await axiosInstance.post(`/api/invitations/request`, { userId, teamId: profile._id });
      toast.success("Request to join team sent successfully");
    } catch (error) {
      toast.error("Failed to send request to join team");
      console.error("Error requesting to join team", error);
    }
  };

  if (!profile) return <div>Loading...</div>;

  return (
    <div className="profileCard">
      <div className="profileHeader">
        <img src="/path/to/avatar.png" alt="avatar" className="profileAvatar" />
        <div className="profileName">
          <h2>{profile.name}</h2>
          <p>{profile.eth}</p>
        </div>
        <button onClick={onClose} className="closeButton">X</button>
      </div>
      <div className="profileBody">
        <p className="profileBio">{profile.bio}</p>
        <p className="profileDescription">{profile.description}</p>
        {type === "team" ? (
          <button onClick={handleJoinTeam} className="joinButton">Join</button>
        ) : (
          <>
            <p className="pCard">Team: {teamDetails ? teamDetails.name : "No team"}</p>
            <p className="pCard">Tournament Elo: {profile.tournaments?.tournamentElo ?? 0}</p>
            <p className="pCard">Bet Elo: {profile.betElo}</p>
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileCard;