import React from 'react';
import styles from '../../styles/GameTabs.module.css';

function GameTabs({ activeTab, onTabClick }) {
  return (
    <div className={styles.gameTabs}>
      <button className={`${styles.gameTab} ${activeTab === 'all' ? styles.active : ''}`} onClick={() => onTabClick('all')}>Tous</button>
      <button className={`${styles.gameTab} ${activeTab === 'lol' ? styles.active : ''}`} onClick={() => onTabClick('lol')}>LoL</button>
      <button className={`${styles.gameTab} ${activeTab === 'csgo' ? styles.active : ''}`} onClick={() => onTabClick('csgo')}>CS:GO</button>
      <button className={`${styles.gameTab} ${activeTab === 'dota2' ? styles.active : ''}`} onClick={() => onTabClick('dota2')}>Dota 2</button>
      <button className={`${styles.gameTab} ${activeTab === 'valorant' ? styles.active : ''}`} onClick={() => onTabClick('valorant')}>Valorant</button>
    </div>
  );
}

export default GameTabs;
