import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosConfig";
import "../styles/Leaderboard.css";

const EsportLeaderboard = ({ game }) => {
  const [players, setPlayers] = useState([]);

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await axiosInstance.get(`/api/leaderboards/${game}/players`);
        const sortedData = response.data.sort((a, b) => b.tournamentElo - a.tournamentElo);

        // Réassigner les rangs après le tri
        const rankedData = sortedData.map((player, index) => ({
          ...player,
          rang: index + 1
        }));

        setPlayers(rankedData);
      } catch (error) {
        console.error("Erreur lors de la récupération des joueurs :", error);
      }
    };

    fetchPlayers();
  }, [game]);

  return (
    <div>
      <div className="ladder">
        <h2>Leaderboard {game}</h2>
        <table>
          <thead>
            <tr>
              <th>Rang</th>
              <th>Joueur</th>
              <th>Total Points</th>
            </tr>
          </thead>
          <tbody>
            {players.map((player, index) => (
              <tr key={index}>
                <td>{player.rang}</td>
                <td>
                  <div>{player.name}</div>
                </td>
                <td>{player.tournamentElo}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default EsportLeaderboard;
