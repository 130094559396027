import React, { useEffect, useState } from 'react';
import axiosInstance from '../axiosConfig';
import '../styles/TournamentBracket.css';

const TournamentBracket = ({ tournamentId }) => {
  const [matches, setMatches] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMatches = async () => {
      try {
        const response = await axiosInstance.get(`/api/tournaments/${tournamentId}/bracket`);
        console.log("Fetched matches:", response.data.matches);
        setMatches(response.data.matches);
      } catch (error) {
        setError('Failed to fetch tournament data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchMatches();
  }, [tournamentId]);

  const generateBracket = (matches) => {
    if (!matches || matches.length === 0) {
      return [];
    }

    // Find the number of teams
    const teams = matches.reduce((acc, match) => {
      match.teams.forEach(team => {
        if (team) acc.add(team._id);
      });
      return acc;
    }, new Set());

    const totalTeams = teams.size;
    const totalRounds = Math.ceil(Math.log2(totalTeams));

    const bracket = [];
    for (let i = 1; i <= totalRounds; i++) {
      const roundMatches = matches.filter(match => match.roundNumber === i);
      const matchCount = Math.pow(2, totalRounds - i);
      // Fill missing matches with placeholder matches
      while (roundMatches.length < matchCount) {
        roundMatches.push({
          _id: `placeholder-${i}-${roundMatches.length}`,
          teams: [null, null],
          roundNumber: i,
        });
      }
      bracket.push({ roundNumber: i, matches: roundMatches });
    }

    return bracket;
  };

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const bracket = generateBracket(matches);

  return (
    <div className="tournament-bracket">
      {bracket.map((round) => (
        <div key={round.roundNumber} className="round">
          <h3>Round {round.roundNumber}</h3>
          {round.matches.map((match, index) => (
            <div key={`${match._id}-${index}`} className="match">
              <div className={`team ${match.winner === match.teams[0]?._id ? 'winner' : ''}`}>
                {match.teams[0]?.name || 'TBD'}
              </div>
              <div className="vs">vs</div>
              <div className={`team ${match.winner === match.teams[1]?._id ? 'winner' : ''}`}>
                {match.teams[1]?.name || 'TBD'}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};  

export default TournamentBracket;
