// WalletConnector.js
import React, { useEffect, useState, useCallback } from "react";
import Web3 from "web3";
import { useWallet } from "../context/WalletContext";
import ProfileModal from "./ProfileModal";
import Dashboard from "./Dashboard";
import axiosInstance from "../axiosConfig";

const WalletConnector = () => {
  const { account, setAccount } = useWallet();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [bio, setBio] = useState("");
  const [profile, setProfile] = useState(null);

  const loadBlockchainData = useCallback(async () => {
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      const accounts = await web3.eth.getAccounts();
      if (accounts.length > 0) {
        setAccount(accounts[0]);
        await fetchProfile(accounts[0]);
      }
    } else {
      console.log(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  }, [setAccount]);

  useEffect(() => {
    loadBlockchainData();

    // Listen for account changes
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setProfile(null); // Réinitialiser le profil lors du changement de compte
          setName("");
          setBio("");
          loadBlockchainData(accounts[0]);
        }
      });
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener("accountsChanged", loadBlockchainData);
      }
    };
  }, [loadBlockchainData]);

  const fetchProfile = async (eth) => {
    try {
      const res = await axiosInstance.get(`/api/users/eth/${eth}`);
      if (res.data) {
        setName(res.data.name);
        setBio(res.data.bio);
        setProfile(res.data);
      } else {
        setIsModalOpen(true);
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        console.log("Profile not found, it is a first login.");
        setIsModalOpen(true);
      } else {
        console.error("Error fetching profile:", err);
      }
    }
  };

  const saveProfile = (profile) => {
    setName(profile.name);
    setBio(profile.bio);
    setProfile(profile);
    setIsModalOpen(false);
  };

  const disconnectWallet = () => {
    setAccount(null);
    setProfile(null);
    setName("");
    setBio("");
  };

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({ method: "eth_requestAccounts" });
        await loadBlockchainData();
      } catch (error) {
        console.error("User denied account access");
      }
    } else {
      alert(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  };
  
  const handleModalClose = () => {
    // Logique pour vérifier si des modifications ont été effectuées
    if (!profile || (profile && (profile.name !== name || profile.bio !== bio))) {
      disconnectWallet();
    }
    setIsModalOpen(false);
  };

  return (
    <div>
      {account ? (
        <>
          <Dashboard name={name} eth={account} bio={bio} saveProfile={saveProfile} currentUserId={profile} />
          {isModalOpen && (
            <ProfileModal
              isOpen={isModalOpen}
              onRequestClose={handleModalClose}
              saveProfile={saveProfile}
              eth={account}
              existingProfile={profile}
              disconnectWallet={disconnectWallet}
            />
          )}
        </>
      ) : (
        <button className="btn_wallet" onClick={connectWallet}>
          Connect Wallet
        </button>
      )}
    </div>
  );
};

export default WalletConnector;
