import { useState } from "react";
import axiosInstance from "../../axiosConfig";
import { useWallet } from "../../context/WalletContext";
import "./AdminPanel.css";

const AdminTournamentForm = () => {
  const { account } = useWallet();
  const [notification, setNotification] = useState();

  const [tournament, setTournament] = useState({
    name: "",
    game: "valorant",
    cashprize: "",
    date: "",
    entryFee: "",
    maxTeams: "",
    backgroundImage: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTournament((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    setTournament((prev) => ({
      ...prev,
      backgroundImage: e.target.files[0],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    for (const key in tournament) {
      if (key === "teams") {
        formData.append(key, JSON.stringify(tournament[key]));
      } else {
        formData.append(key, tournament[key]);
      }
    }

    try {
      await axiosInstance.post("/api/tournaments", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      setTournament({
        name: "",
        game: "valorant",
        cashprize: "",
        date: "",
        entryFee: "",
        maxTeams: "",
        backgroundImage: null,
      });
    } catch (error) {
      console.error("Erreur lors de la création du tournoi", error);
    }
  
    const textToCopy = account;
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setNotification("Tournoi crée avec succès !");
        setTimeout(() => {
          setNotification("");
        }, 2000);
      })
      .catch((err) => {
        setNotification("Fail..." + err);
        setTimeout(() => {
          setNotification("");
        }, 2000);
      });
  };

  return (
    <form onSubmit={handleSubmit} className="admin-tournament-form">
      <h2>Créer un Tournoi</h2>
      <input
        type="text"
        name="name"
        placeholder="Nom du tournoi"
        value={tournament.name}
        onChange={handleChange}
        required
      />
      <select
        name="game"
        value={tournament.game}
        onChange={handleChange}
        required
      >
        <option value="valorant">Valorant</option>
        <option value="rocketleague">Rocket League</option>
        <option value="leagueoflegends">League of Legends</option>
        <option value="fortnite">Fortnite</option>
        <option value="olympusgame">Olympus Game</option>
      </select>
      <input
        type="number"
        name="cashprize"
        placeholder="Cashprize"
        value={tournament.cashprize}
        onChange={handleChange}
        required
      />
      <input
        type="date"
        name="date"
        value={tournament.date}
        onChange={handleChange}
        required
      />
      <input
        type="number"
        name="entryFee"
        placeholder="Prix d'entrée"
        value={tournament.entryFee}
        onChange={handleChange}
        required
      />
            <input
        type="number"
        name="maxTeams"
        placeholder="Teams max"
        value={tournament.maxTeams}
        onChange={handleChange}
        required
      />
      <input
        type="file"
        name="backgroundImage"
        accept="image/*"
        onChange={handleImageChange}
        required
      />
      <button type="submit" >
        Créer un tournoi
      </button>
      {notification && <div className="alert">{notification}</div>}
    </form>
  );
};

export default AdminTournamentForm;
