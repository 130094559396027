import React, { useState, useCallback, useEffect } from 'react';
import TwitchLiveStreams from '../TwitchLiveStreams';

const BentoHomeView = () => {
  const [expandedIndex, setExpandedIndex] = useState(0);
  const [isLive, setIsLive] = useState(true);  // Commencez avec true par défaut
  const [streamChecked, setStreamChecked] = useState(false);

  const handleItemClick = (index) => {
    setExpandedIndex(index);
  };

  const handleLiveStatusChange = useCallback((liveStatus) => {
    console.log("Live status changed:", liveStatus);
    setIsLive(liveStatus);
    setStreamChecked(true);

    if (!liveStatus) {
      setExpandedIndex(1);
    }
  }, []);

  const channels = [
    "baptouazz_",
    "coachjim91",
    "valorant",
  ];

  useEffect(() => {
    console.log("isLive updated:", isLive, "streamChecked:", streamChecked);
  }, [isLive, streamChecked, expandedIndex]);

  const bentoItems = [
    <div key="twitch-stream">
      <div className='App_twitch'>
      <TwitchLiveStreams 
        channels={channels} 
        onLiveStatusChange={handleLiveStatusChange} 
      />
      </div>
    </div>,
    <div key="jsx2">Contenu JSX 2</div>,
    <div key="jsx3">Contenu JSX 3</div>,
    <div key="jsx4">Contenu JSX 4</div>,
    <div key="jsx5">Contenu JSX 5</div>,
    <div key="jsx6">Contenu JSX 6</div>,
    <div key="jsx7">Contenu JSX 7</div>,
  ];

  return (
    <div className="bento-container">
      {bentoItems.map((content, index) => (
        <div
          key={index}
          className={`bento-item ${expandedIndex === index ? "expanded" : ""} ${index === 0 && streamChecked && !isLive ? "hidden" : ""}`}
          onClick={() => handleItemClick(index)}
        >
          {expandedIndex === index && content}
          {expandedIndex !== index && (
            <div className="content">
              {index === 0 && isLive && (
                <>
                  <span className="live-indicator"></span>
                  <h2>DIREKT</h2>
                </>
              )}
              {index === 1 && <h2>TOURNAMENTS</h2>}
              {index === 2 && <h2>BETS</h2>}
              {index === 3 && <h2>STORE</h2>}
              {index === 4 && <h2>$REKT</h2>}
              {index === 5 && <h2>SOCIALS</h2>}
              {index === 6 && <h2>CONTACT</h2>}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default BentoHomeView;