import React from "react";
import styles from "./Whitepaper.module.css";
import tokenomics from "../../assets/tok.png";

const WhitePaper = () => {
  const sections = [
    { id: "introduction", title: "Introduction" },
    { id: "problem", title: "Mission" },
    { id: "solution", title: "Team" },
    { id: "technology", title: "Esport" },
    { id: "benefits", title: "Bet" },
    { id: "use-cases", title: "Leaderboard" },
    { id: "team", title: "Marketplace" },
    { id: "conclusion", title: "Economy" },
    { id: "roadmap", title: "Roadmap" },
  ];

  return (
    <div className={styles.containerWhitepaper}>
      <main className={styles.contentWhitepaper}>
        <h1>Rekt Inc</h1>
        <div className={styles.imagePlaceholder}>Overview</div>

        <section id="introduction">
          <h2>1. Introduction</h2>
          <p>
            REKT Inc is an innovative platform dedicated to{" "}
            <span className={styles.highlight}>eSport</span> and gaming,
            combining an esports structure with{" "}
            <span className={styles.highlight}>tournaments</span>,{" "}
            <span className={styles.highlight}>bets</span> and a governance
            token ($REKT) focused on Play-to-Earn and traditional video games.
            Our goal is to create a dynamic and engaged community while
            facilitating transparent and secure transactions within our
            ecosystem.
          </p>
        </section>

        <section id="problem">
          <h2>2. Mission</h2>
          <p>
            Our mission at REKT Inc is to revolutionize the esports and gaming
            industry by integrating blockchain technology into every facet of
            our platform. We aim to provide innovative and secure solutions that
            cater to the needs of players, teams, and fans alike. By leveraging
            the power of decentralized finance (DeFi) and blockchain, we strive
            to create a transparent and fair environment where users can
            participate in tournaments, place bets on their favorite esports
            events, and engage in governance activities.
          </p>
          <p>
            We are committed to fostering a vibrant community that is driven by
            engagement and collaboration. Through our $REKT governance token, we
            empower our users to have a direct say in the future development of
            the platform, ensuring that it evolves in a way that best serves
            their interests. Our platform also aims to bridge the gap between
            traditional gaming and the burgeoning Play-to-Earn model, offering
            users new opportunities to earn rewards and monetize their gaming
            skills.
          </p>
          <p>
            By providing a seamless and intuitive user experience, robust
            security measures, and a fair economic model, REKT Inc aspires to
            become the leading platform for esports enthusiasts and blockchain
            innovators. We believe that by combining the best aspects of both
            worlds, we can drive the next generation of esports and gaming.
          </p>
        </section>

        <section id="solution">
          <h2>3. Team and our goal</h2>
          <p>
            We have assembled a dedicated and diverse team of professionals who
            are passionate about esports, gaming, and blockchain technology. Our
            collective expertise drives our mission to create a revolutionary
            platform that caters to the needs of our users.
          </p>
          <div className={styles.memberSection}>
            <h3>Founders</h3>
            <div className={styles.teamMember}>
              <strong>Benjamin B.</strong>{" "}
              <a
                href="https://www.linkedin.com/in/benjamin-b-766ba722b/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png"
                  alt="LinkedIn"
                  className={styles.logoLinkedin}
                />
              </a>
            </div>
            <div className={styles.teamMember}>
              <strong>Luc W.</strong>{" "}
              <a
                href="https://www.linkedin.com/in/luc-w-180469315/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png"
                  alt="LinkedIn"
                  className={styles.logoLinkedin}
                />
              </a>
            </div>
            <div className={styles.teamMember}>
              <strong>Thibault D.</strong>{" "}
              <a
                href="https://www.linkedin.com/in/thibault-delli-colli-b30a80242/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png"
                  alt="LinkedIn"
                  className={styles.logoLinkedin}
                />
              </a>
            </div>
          </div>
          <div className={styles.memberSection}>
            <h3>CMO</h3>
            <div className={styles.teamMember}>
              <strong>Baptiste C.</strong>{" "}
              <a
                href="https://www.linkedin.com/in/baptiste-chesnel-/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png"
                  alt="LinkedIn"
                  className={styles.logoLinkedin}
                />
              </a>
            </div>
          </div>
          <div className={styles.memberSection}>
            <h3>CM</h3>
            <div className={styles.teamMember}>
              <strong>Gor M.</strong>{" "}
              <a
                href="https://www.linkedin.com/in/gor-mardumov-b82262315/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png"
                  alt="LinkedIn"
                  className={styles.logoLinkedin}
                />
              </a>
            </div>
          </div>
          <div className={styles.memberSection}>
            <h3>Developers</h3>
            <div className={styles.teamMember}>
              <strong>Malay</strong>
            </div>
            <div className={styles.teamMember}>
              <strong>Luc W.</strong>{" "}
              <a
                href="https://www.linkedin.com/in/luc-woillet-180469315/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png"
                  alt="LinkedIn"
                  className={styles.logoLinkedin}
                />
              </a>
            </div>
          </div>
          <div className={styles.memberSection}>
            <h3>UI/UX Designer</h3>
            <div className={styles.teamMember}>
              <strong>Thibault D.</strong>{" "}
              <a
                href="https://www.linkedin.com/in/thibault-delli-colli-b30a80242/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png"
                  alt="LinkedIn"
                  className={styles.logoLinkedin}
                />
              </a>
            </div>
          </div>
          <div className={styles.memberSection}>
            <h3>SysAdmin</h3>
            <div className={styles.teamMember}>
              <strong>Benjamin B.</strong>{" "}
              <a
                href="https://www.linkedin.com/in/benjamin-b-766ba722b/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png"
                  alt="LinkedIn"
                  className={styles.logoLinkedin}
                />
              </a>
            </div>
            <p>
              <strong>Esports and Tournament Coordinators:</strong> REKT Inc is
              a French esports organization that works closely with the French
              Federation of Video Games (FFJV). This team organizes and manages
              esports events and tournaments on our platform, ensuring that
              competitions are fair, exciting, and rewarding for participants.
              Their expertise in esports, coupled with our strong connection to
              the FFJV, helps us provide high-quality events that attract top
              talent and engage our community.
            </p>
          </div>
        </section>

        <section id="technology">
          <div className={styles.imagePlaceholder}>Ecosystem</div>
          <h2>4. Esport</h2>
          <p>
            REKT Inc is committed to revolutionizing the esports industry by
            leveraging blockchain technology to create a dynamic and engaging
            platform for both professional and amateur players.
          </p>
          <h3>E-Sports Competitions :</h3>
          <p>
            Our platform will also host regular amateur tournaments, allowing
            gamers of all skill levels to compete. Participants in these
            competitions can earn rewards in $REKT tokens, fostering a
            competitive and rewarding environment for everyone.
          </p>
          <h3>Token Allocation :</h3>
          <p>
            As part of the esports section of REKT, 7.5% of tokens generated in
            the in-game economy are allocated specifically to support esports
            competitions and player rewards. These tokens are used for:
          </p>
          <ul>
            <li>
              <strong>Prizes and Rewards:</strong> Tokens are distributed as
              prizes to the winners of tournaments and esports events, as well
              as to the highest ranked players on the leaderboards.
            </li>
            <li>
              <strong>Organization of Events:</strong> Tokens are used to
              organize and promote tournaments, special events, and other
              esports activities.
            </li>
            <li>
              <strong>Player Support:</strong> Tokens can be used to provide
              financial support to talented players who actively participate in
              REKT esports competitions.
            </li>
          </ul>
        </section>

        <section id="benefits">
          <h2>5. Bet</h2>
          <p>
            The Bet section of our platform is designed to provide a seamless
            and engaging betting experience for users. It integrates various
            games and competitions, offering opportunities to place bets and
            potentially earn rewards.
          </p>
          <div className={styles.grid}>
            <div className={styles.card}>
              <h3>Bet Placement</h3>
              <p>
                The betting interface is intuitive and user-friendly, making it
                easy for users to place bets. We offer various bet types,
                including single bets, multiple bets, and system bets, to cater
                to different user preferences.
              </p>
            </div>
            <div className={styles.card}>
              <h3>Security and Fairness</h3>
              <p>
                All bets are recorded on the blockchain, ensuring transparency
                and immutability. Smart contracts automate bet execution and
                payouts, reducing the risk of fraud.
              </p>
            </div>
            <div className={styles.card}>
              <h3>Data Integrity</h3>
              <p>
                We use reliable sources for real-time game data to ensure the
                integrity of betting outcomes. Advanced algorithms detect and
                prevent any form of cheating or manipulation.
              </p>
            </div>
          </div>
        </section>

        <section id="use-cases">
          <h2>6. Leaderboard</h2>
          <p>
            The Leaderboards section of our platform highlights the top
            performers across various aspects of the ecosystem, including
            betting and tournament participation. By
            recognizing and rewarding the top 50 users in each category, we aim
            to incentivize engagement and foster a competitive yet rewarding
            environment.
          </p>
          <h3>Tournament Leaderboard</h3>
          <p>
            <strong>Top Teams and Players:</strong>This leaderboard highlights
            the best performing teams and individual players across various
            games in tournaments.
          </p>
          <p>
            <strong>Rewards:</strong> The top 50 teams and players receive
            exclusive rewards, including rare NFTs, bonus tokens, and special
            in-game items.
          </p>
          <h3>Bet Leaderboard</h3>
          <p>
            <strong>Performance Gains:</strong> This leaderboard tracks users
            based on their performance and success in betting activities.
          </p>
          <p>
            <strong>Metrics:</strong> Users are ranked according to metrics such
            as total winnings, winning streaks, and bet accuracy.
          </p>
          <p>
            <strong>Rewards:</strong> The top 50 users receive exclusive
            rewards, including rare NFTs and bonus tokens.
          </p>
        </section>

        <section id="team">
          <h2>8. Marketplace</h2>
          <p>
            The Marketplace section of our platform is a dynamic hub where users
            can buy, sell, and trade various digital and physical items.
            Leveraging blockchain technology, our marketplace offers unique,
            verifiable digital assets (NFTs) and exclusive merchandise,
            enhancing the overall user experience and engagement. Additionally,
            users can equip their avatars with NFT items to gain performance
            bonuses in tournaments, betting activities, and leaderboard rewards.
          </p>
          <div className={styles.grid}>
            <div className={styles.card}>
              <h3>NFT Items</h3>
              <p>
                <strong>Digital Merchandise:</strong> Users can purchase NFTs
                representing digital merchandise such as T-shirts, caps, and
                other accessories.
              </p>
              <p>
                <strong>Rarity Levels:</strong> Each NFT item is available in
                different rarity levels, ranging from common to legendary,
                providing exclusivity and value.
              </p>
              <p>
                <strong>Performance Boosts:</strong> Certain rare NFT items
                offer performance boosts in tournaments, betting activities, and
                leaderboard rewards for amateur teams.
              </p>
            </div>
            <div className={styles.card}>
              <h3>Physical Merchandise</h3>
              <p>
                <strong>Player-Branded Items:</strong> The marketplace offers
                real-world T-shirts and accessories featuring your favorite
                players.
              </p>
              <p>
                <strong>Exclusive Collections:</strong> Limited edition items
                and collections are available, ensuring fans can own a piece of
                exclusive merchandise.
              </p>
            </div>
            <div className={styles.card}>
              <h3>Avatars and NFT Integration</h3>
              <p>
                Each user can create and customize their own avatar. Users must
                equip their avatars with purchased NFT items to activate
                performance bonuses on tournaments and bets rewards. The
                equipped items are visually represented on the user's avatar,
                showcasing their collection and enhancing their digital
                identity.
              </p>
            </div>
          </div>
        </section>

        <section id="conclusion">
          <div className={styles.imagePlaceholder}>Economy</div>
          <h2>9. Tokenomics</h2>
          <p>
            One of the main objectives of $REKT tokenomics is to encourage
            participation and engagement in esports tournaments and betting. By
            offering attractive rewards in $REKT, we incentivize users to
            actively invest in the platform.
          </p>
          <p>
            To promote the adoption of the platform, an equitable initial
            distribution and incentives have been put in place. This includes
            airdrops for early users and promotional campaigns aimed at
            attracting new participants.
          </p>
          <h3>Initial Distribution</h3>
          <img
            src={tokenomics}
            alt="Tokenomics"
            style={{
              minWidth: "50%",
              maxWidth: "100%",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          />
        </section>

        <section id="roadmap">
          <h2>7. Roadmap</h2>
          <h3>Phase 1: Pre-launch (June 2024 - August 2024)</h3>
          <h4>Research and Development</h4>
          <ul>
            <li>Team formation and role definition.</li>
            <li>
              Designing the esports structure and teams for various games.
            </li>
          </ul>
          <h4>Design and Planning</h4>
          <ul>
            <li>Collaborating to define the UI/UX of the platform.</li>
            <li>Designing smart contracts for tokenomics.</li>
          </ul>
          <h4>Initial Development</h4>
          <ul>
            <li>Developing esports tournaments and betting systems.</li>
          </ul>
          <h4>Preliminary Launch</h4>
          <ul>
            <li>Launching the platform to promote the esports structure.</li>
          </ul>

          <h3>Phase 2: Testnet Launch and ICO (September 2024)</h3>
          <h4>Testnet Launch</h4>
          <ul>
            <li>Bug bounty for discovering bugs.</li>
            <li>Setting up the testnet for limited public trials.</li>
            <li>Collecting user feedback to improve features.</li>
          </ul>
          <h4>ICO</h4>
          <ul>
            <li>
              Launching the Initial Coin Offering (ICO) to raise funds and
              distribute $REKT tokens.
            </li>
            <li>Marketing campaigns to attract early investors and users.</li>
          </ul>
          <h4>Optimization</h4>
          <ul>
            <li>
              Optimizing smart contracts and user interface based on testnet
              feedback.
            </li>
          </ul>

          <h3>Phase 3: Official Launch (October 2024)</h3>
          <h4>Platform Launch</h4>
          <ul>
            <li>Official launch with all basic features operational.</li>
            <li>
              Activating esports tournaments and betting systems with $REKT
              payments.
            </li>
          </ul>
          <h4>Initial $REKT Distribution</h4>
          <ul>
            <li>Distributing $REKT tokens according to the tokenomics plan.</li>
          </ul>
          <h4>Community Engagement</h4>
          <ul>
            <li>
              Launching the forum with an up/down voting system for proposals.
            </li>
            <li>Rewards for active contributions on the forum.</li>
          </ul>

          <h3>Phase 4: Expansion and Integration</h3>
          <h4>Feature Expansion</h4>
          <ul>
            <li>Integrating new games and expanding esports teams.</li>
            <li>Adding additional features based on community feedback.</li>
          </ul>
          <h4>Strategic Partnerships</h4>
          <ul>
            <li>
              Establishing partnerships with other platforms and companies.
            </li>
            <li>
              Collaborating with sponsors and tournament organizers for
              exclusive events.
            </li>
          </ul>
          <h4>Audits and Security</h4>
          <ul>
            <li>
              Conducting regular audits of smart contracts to ensure security.
            </li>
            <li>
              Implementing transparency mechanisms for governance processes.
            </li>
          </ul>

          <p>
            Note: This whitepaper provides an overview of Rekt and can be used
            as a basis for developing more detailed plans regarding the
            development, launch, and management of the project.
          </p>
        </section>
      </main>

      <aside className={styles.sidebarWhitepaper}>
        <h2>Sommaire</h2>
        <ul>
          {sections.map((section) => (
            <li key={section.id}>
              <a href={`#${section.id}`}>{section.title}</a>
            </li>
          ))}
        </ul>
      </aside>
    </div>
  );
};

export default WhitePaper;
