import React from "react";
import Profile from "./Profile";
import Modal from "react-modal";
import "../../styles/ModalProfile.css";

const ModalProfile = ({ isOpen, onRequestClose, type, id, userId }) => {
  if (!isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Profile Modal"
      className="modal-content-profile"
      overlayClassName="modal-profile-overlay"
      ariaHideApp={false}
    >
      <Profile type={type} id={id} userId={userId} />
    </Modal>
  );
};

export default ModalProfile;
