import React from 'react';
import styles from '../../styles/MatchCard.module.css';

function MatchCard({ match, onSelectBet }) {
  return (
    <div className={styles.matchCard}>
      <div className={styles.matchHeader}>
        <span className={styles.matchTitle}>{match.title}</span>
        <span className={styles.matchTime}>{match.time}</span>
      </div>
      <div className={styles.teamVsTeam}>
        <div className={styles.team}>
          <img src={match.team1.logo} alt={match.team1.name} />
          <p>{match.team1.name}</p>
        </div>
        <span className={styles.vs}>VS</span>
        <div className={styles.team}>
          <img src={match.team2.logo} alt={match.team2.name} />
          <p>{match.team2.name}</p>
        </div>
      </div>
      <div className={styles.odds}>
        <button className={styles.oddButton} onClick={() => onSelectBet(match.id, 1, match.odds.team1)}>{match.odds.team1}</button>
        <button className={styles.oddButton} onClick={() => onSelectBet(match.id, 2, match.odds.team2)}>{match.odds.team2}</button>
      </div>
    </div>
  );
}

export default MatchCard;
