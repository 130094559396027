import React, { useState, useEffect } from "react";
import axiosInstance from "../axiosConfig";
import { Modal, Button, Form } from "react-bootstrap";
import "../styles/ProfileModal.css";

const ProfileModal = ({
  isOpen,
  onRequestClose,
  saveProfile,
  eth,
  existingProfile,
  disconnectWallet,
}) => {
  const [profile, setProfile] = useState({
    name: existingProfile?.name || "",
    eth: eth || "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [bio, setBio] = useState(existingProfile?.bio || "");
  const [isNameValid, setIsNameValid] = useState(true);

  useEffect(() => {
    if (existingProfile) {
      setProfile(existingProfile);
      setBio(existingProfile.bio || "");
    }
  }, [existingProfile]);

  const isValidEthereumAddress = (address) => {
    return /^0x[a-fA-F0-9]{40}$/.test(address);
  };

  const validateNameFormat = (name) => {
    return name.length >= 3 && name.length <= 20 && /^[a-zA-Z0-9]+$/.test(name);
  };

  const handleSaveProfile = async () => {
    if (!isValidEthereumAddress(profile.eth)) {
      setErrorMessage("L'adresse Ethereum n'est pas valide.");
      return;
    }
    if (!isNameValid) {
      setErrorMessage("Le pseudo n'est pas valide.");
      return;
    }

    try {
      console.log("Sending profile data:", profile);
      const response = profile
        ? await axiosInstance.put(`/api/users/${profile.eth}`, {
            name: profile.name,
            bio: bio,
          })
        : await axiosInstance.post("/api/users", profile);
      console.log("Profile saved:", response.data);
      saveProfile(response.data);
      onRequestClose();
    } catch (error) {
      console.error(
        "Error during profile save:",
        error.response ? error.response.data : error.message
      );
      const errorMsg =
        error.response?.data?.msg || "Erreur lors de la sauvegarde du profil";
      setErrorMessage(errorMsg);
    }
  };

  const handleClose = () => {
    onRequestClose();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });

    if (name === "name") {
      const isValid = validateNameFormat(value);
      setIsNameValid(isValid);
    }
  };

  const handleBioChange = (e) => {
    setBio(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting profile:", profile);
    handleSaveProfile();
  };

  return (
    <Modal
      show={isOpen}
      onHide={handleClose}
      centered
      className="custom-profile-modal"
    >
      <Modal.Body className="modal-profile-body">
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName">
            <Form.Label>Nom</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={profile.name}
              onChange={handleChange}
              required
              isInvalid={!isNameValid}
            />
            {!isNameValid && (
              <Form.Control.Feedback type="invalid">
                Le pseudo doit avoir entre 3 et 20 caractères et ne contenir que
                des lettres et des chiffres.
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId="formEthAddress">
            <Form.Label>Adresse Ethereum</Form.Label>
            <Form.Control
              type="text"
              name="eth"
              value={profile.eth}
              className="eth-address-input-profile"
              readOnly
              required
            />
          </Form.Group>
          <Form.Group controlId="formBio">
            <Form.Label>Bio</Form.Label>
            <Form.Control
              as="textarea"
              value={bio}
              onChange={handleBioChange}
              className="no-resize"
              maxLength="100"
            />
          </Form.Group>
          <Button variant="primary" type="submit" disabled={!isNameValid}>
            Sauvegarder
          </Button>
        </Form>
        {errorMessage && <div className="error-message">{errorMessage}</div>}
      </Modal.Body>
    </Modal>
  );
};

export default ProfileModal;
