import React, { useState } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import TournamentsList from "./TournamentList";
import EsportLeaderboard from "./EsportLeaderboard";
import gameSettings from "./config/gameSettings";
import "../styles/Esport.css";

const Esport = () => {
  const [mode, setMode] = useState("amateur");
  const [game, setGame] = useState("valorant");

  const getContent = () => {
    const settings = gameSettings[game];
    if (!settings) {
      console.error(`Settings not found for game: ${game}`);
      return <div>Invalid game settings</div>;
    }

    if (mode === "amateur") {
      switch (game) {
        case "valorant":
          return <TournamentsList game={game} settings={settings} />;
        case "rocketleague":
          return <TournamentsList game={game} settings={settings} />;
        case "leagueoflegends":
          return <TournamentsList game={game} settings={settings} />;
        case "fortnite":
          return <TournamentsList game={game} settings={settings} />;
        case "olympusgame":
          return <TournamentsList game={game} settings={settings} />;
        default:
          return <div>Select a game</div>;
      }
    } else {
      switch (game) {
        case "valorant":
          return <div>Pro content for Valorant</div>;
        case "rocketleague":
          return <div>Pro content for Rocket League</div>;
        case "leagueoflegends":
          return <div>Pro content for League of Legends</div>;
        case "fortnite":
          return <div>Pro content for Fortnite</div>;
        case "olympusgame":
          return <div>Pro content for Olympus Game</div>;
        default:
          return <div>Select a game</div>;
      }
    }
  };

  return (
    <div className="base">
      <div className="container">
        <div className="pro-amateur">
          <button
            className={`pro ${mode === "pro" ? "selected" : ""}`}
            onClick={() => setMode("pro")}
          >
            Pro
          </button>
          <button
            className={`amateur ${mode === "amateur" ? "selected" : ""}`}
            onClick={() => setMode("amateur")}
          >
            Amateur
          </button>
        </div>
        <div className="game-tabs">
          <button
            className={`valorant ${game === "valorant" ? "selected" : ""}`}
            onClick={() => setGame("valorant")}
          >
            Valorant
          </button>
          <button
            className={game === "rocketleague" ? "selected" : ""}
            onClick={() => setGame("rocketleague")}
          >
            RL
          </button>
          <button
            className={game === "leagueoflegends" ? "selected" : ""}
            onClick={() => setGame("leagueoflegends")}
          >
            LOL
          </button>
          <button
            className={game === "fortnite" ? "selected" : ""}
            onClick={() => setGame("fortnite")}
          >
            Fortnite
          </button>
          <button
            className={`olympus ${game === "olympusgame" ? "selected" : ""}`}
            onClick={() => setGame("olympusgame")}
          >
            Olympus Game
          </button>
        </div>
        <div className="esportDiv">
          <TransitionGroup>
            <CSSTransition
              key={`${mode}-${game}`}
              timeout={300}
              classNames="slide"
            >
              {getContent()}
            </CSSTransition>
          </TransitionGroup>
        </div>
      </div>
      <div className="container2">
        <EsportLeaderboard game={game} />
      </div>
    </div>
  );
};

export default Esport;
