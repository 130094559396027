import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import "./App.css"; // Assurez-vous d'importer le fichier CSS
import "react-toastify/dist/ReactToastify.css";
import logo from "./REKT_Logo.png";
import homeIcon from "./assets/icons/Icon_home.png";
import storeIcon from "./assets/icons/Icon_shop.png";
import logoutIcon from "./assets/icons/Icon_disconect.png";

// Components
import Home from "./components/Home/Home";
import Esport from "./components/esport";
import Bet from "./components/Bet/Bet";
import Marketplace from "./components/Marketplace";
import AdminPage from "./components/Admin/AdminPage";
import Boutique from "./components/Boutique/Boutique";
import NFT from "./components/NFT";
import AdminRoute from "./components/AdminRoute";
import CachedImage from "./components/CachedImage";
import WalletConnector from "./components/WalletConnector";
import { useWallet } from "./context/WalletContext";
import ChatButton from "./components/ChatButton";
import ChatModal from "./components/ChatModal";
import TeamGestionModal from "./components/TeamGestionModal";
import Profile from "./components/Profile/Profile";
import { ToastContainer } from "react-toastify";
import NotificationButton from "./components/Notifications/NotificationsButton";
import axiosInstance from "./axiosConfig";
import WhitePaper from "./components/Whitepaper/Whitepaper";

const ADMIN_ADDRESS = process.env.REACT_APP_ADMIN_ADDRESS;

function App() {
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [currentMatchId, setCurrentMatchId] = useState(null);
  const [currentChatType, setCurrentChatType] = useState("general");
  const { account } = useWallet();
  const [isAdmin, setIsAdmin] = useState(false);
  const [profile, setProfile] = useState(null);
  const [, setIsModalOpen] = useState(false);
  const [isTeamGestionModalOpen, setIsTeamGestionModalOpen] = useState(false);

  const openChat = (matchId, chatType) => {
    setCurrentMatchId(matchId);
    setCurrentChatType(chatType);
    setIsChatOpen(true);
  };

  const fetchProfile = async (eth) => {
    try {
      const res = await axiosInstance.get(`/api/users/eth/${eth}`);
      if (res.data) {
        setProfile(res.data);
      } else {
        setIsModalOpen(true);
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        console.log("Profile not found, it is a first login.");
        setIsModalOpen(true);
      } else {
        console.error("Error fetching profile:", err);
      }
    }
  };

  const saveProfile = (profile) => {
    setProfile(profile);
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (account) {
      fetchProfile(account);
      if (
        ADMIN_ADDRESS &&
        account.toLowerCase() === ADMIN_ADDRESS.toLowerCase()
      ) {
        setIsAdmin(true);
      } else {
        setIsAdmin(false);
      }
    }
  }, [account]);

  const openTeamGestionModal = () => {
    setIsTeamGestionModalOpen(true);
  };

  const closeTeamGestionModal = () => {
    setIsTeamGestionModalOpen(false);
  };

  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <Router>
      <div className="App">
        <ToastContainer />
        {account && profile && (
          <>
            <NotificationButton userId={profile._id} />
            <button
              className="teamButton"
              onClick={() => openTeamGestionModal()}
            >
              Team
            </button>
            <ChatButton onClick={openChat} />
            <ChatModal
              isOpen={isChatOpen}
              onClose={() => setIsChatOpen(false)}
              matchId={currentMatchId}
              chatType={currentChatType}
            />
          </>
        )}
        {isTeamGestionModalOpen && (
          <TeamGestionModal
            isOpen={isTeamGestionModalOpen}
            onRequestClose={closeTeamGestionModal}
            eth={account}
          />
        )}
        <WalletConnector
          fetchProfile={fetchProfile}
          saveProfile={saveProfile}
        />
        <nav
          className={`vertical-nav ${isExpanded ? "expanded" : ""}`}
          onMouseEnter={() => setIsExpanded(true)}
          onMouseLeave={() => setIsExpanded(false)}
        >
          <div className="logo">
            <Link to="/">
              <CachedImage src={logo} className="App-logo" alt="logo" />
            </Link>
          </div>

          <div className="nav-item">
            <div>
              <Link className="menu-item" to="/">
                <CachedImage src={homeIcon} alt="Home" className="icon" />
                <span className="navbar-text">HOME</span>
              </Link>
              {isAdmin && (
                <Link className="menu-item" to="/esport">
                  {/* <CachedImage src={tournamentIcon} alt="Home" className="icon" /> */}
                  <span className="navbar-text">TOURNAMENTS</span>
                </Link>
              )}
              {isAdmin && (
                <Link className="menu-item" to="/bet">
                  {/* <CachedImage src={betIcon} alt="Home" className="icon" /> */}
                  <span className="navbar-text">BETS</span>
                </Link>
              )}
              <Link className="menu-item" to="/marketplace">
                <CachedImage src={storeIcon} alt="Store" className="icon" />
                <span className="navbar-text">STORE</span>
              </Link>
              <Link className="menu-item" to="/whitepaper">
                {/* <CachedImage src={wpIcon} alt="Store" className="icon" /> */}
                <span className="navbar-text">WHITEPAPER</span>
              </Link>
              {isAdmin && (
                <Link className="menu-item" to="/admin">
                  ADMIN
                </Link>
              )}
            </div>
            <div className="menu-item-logout">
              <Link to="/" className="menu-item">
                <CachedImage src={logoutIcon} alt="Logout" className="icon" />
                <span className="navbar-text">LOG OUT</span>
              </Link>
            </div>
          </div>
        </nav>
        <div className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/esport" element={<Esport openChat={openChat} />} />
            <Route path="/bet" element={<Bet />} />
            <Route path="/marketplace" element={<Marketplace />} />
            <Route path="/" element={<Boutique />} />
            <Route path="/marketplace/nft" element={<NFT />} />
            <Route path="/profile/:id" component={Profile} />
            <Route
              path="/admin"
              element={
                <AdminRoute
                  element={AdminPage}
                  allowedAddress={ADMIN_ADDRESS}
                />
              }
            />
            <Route path="/whitepaper" element={<WhitePaper />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
